import React from "react";
import DocumentView from "../components/DocumentView";

function Document({
  currentMenuItem,
  setCurrentMenuItem,
  user,
  setUser,
  isAuthenticated,
  setIsAuthenticated,
}) {
  return (
    <div>
      <main className="flex-1">
        <div className="py-6">
          <DocumentView
            // setCurrentDocument={setCurrentDocument}
            currentMenuItem={currentMenuItem}
            setCurrentMenuItem={setCurrentMenuItem}
            user={user}
            setUser={setUser}
            isAuthenticated={isAuthenticated}
            setIsAuthenticated={setIsAuthenticated}
          />
        </div>
      </main>
    </div>
  );
}

export default Document;
