import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  // HomeIcon,
  ChatBubbleLeftEllipsisIcon,
  InboxIcon,
  XMarkIcon,
  DocumentTextIcon,
  DocumentPlusIcon,
  WrenchScrewdriverIcon,
  PencilSquareIcon,
} from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import axios from "axios";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Navigation({
  sidebarOpen,
  setSidebarOpen,
  currentMenuItem,
  setCurrentMenuItem,
  user,
}) {
  const navigate = useNavigate();
  const navigation = [
    {
      name: "Project Chat",
      to: "/dashboard",
      // icon: HomeIcon,
      icon: ChatBubbleLeftEllipsisIcon,
      current: currentMenuItem === "Project Chat",
    },
    {
      name: "Risk Reports",
      to: "/reports",
      // icon: InboxIcon,
      icon: DocumentTextIcon,
      current: currentMenuItem === "Risk Reports",
    },
    {
      name: "Manage Projects",
      to: "/manage",
      icon: PencilSquareIcon,
      current: currentMenuItem === "Manage Projects",
    },
    {
      name: "Add Documents",
      to: "/data-intake",
      icon: DocumentPlusIcon,
      current: currentMenuItem === "Add Documents",
    },
    {
      name: "Generate",
      to: "/new",
      icon: WrenchScrewdriverIcon,
      current: currentMenuItem === "Generate",
    },

    {
      name: "Documents",
      to: "/documents",
      icon: InboxIcon,
      current: currentMenuItem === "Documents",
    },
  ];

  return (
    <>
      <div>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-40 md:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>

            <div className="fixed inset-0 z-40 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-gray-800">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute top-0 right-0 mr-2 pt-2">
                      <button
                        type="button"
                        className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="h-0 flex-1 overflow-y-auto pt-5 pb-4">
                    <div className="flex flex-shrink-0 items-center px-4 text-white text-2xl">
                      {/* <img
                        className="h-8 w-auto"
                        src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=500"
                        alt="Your Company"
                      /> */}
                      TerraScape AI
                    </div>
                    <nav className="mt-5 space-y-1 px-2">
                      {navigation.map((item) => (
                        <Link
                          key={item.name}
                          to={item.to}
                          onClick={() => setCurrentMenuItem(item.name)}
                          className={classNames(
                            item.current
                              ? "bg-gray-900 text-white"
                              : "text-gray-300 hover:bg-gray-700 hover:text-white",
                            "group flex items-center px-2 py-2 text-base font-medium rounded-md"
                          )}
                        >
                          <item.icon
                            className={classNames(
                              item.current
                                ? "text-gray-300"
                                : "text-gray-400 group-hover:text-gray-300",
                              "mr-4 flex-shrink-0 h-6 w-6"
                            )}
                            aria-hidden="true"
                          />
                          {item.name}
                        </Link>
                      ))}
                    </nav>
                  </div>
                  <div className="flex flex-shrink-0 bg-gray-700 p-4">
                    <Link
                      to="/account"
                      className="group block flex-shrink-0"
                      onClick={() => setCurrentMenuItem("Account")}
                    >
                      <div className="flex items-center">
                        <div>
                          {/* <img
                            className="inline-block h-10 w-10 rounded-full"
                            src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                            alt=""
                          /> */}
                        </div>
                        <div className="ml-3">
                          <p className="text-sm font-medium text-white group-hover:text-gray-300">
                            {user?.username || "No Username"}
                          </p>
                          {/* <p className="text-xs font-medium text-gray-300 group-hover:text-gray-200 truncate max-w-[150px]">
                            Test Email
                          </p> */}
                        </div>
                      </div>
                    </Link>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
              <div className="w-14 flex-shrink-0">
                {/* Force sidebar to shrink to fit close icon */}
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden md:fixed md:inset-y-0 md:flex md:w-48 md:flex-col">
          {/* Sidebar component - Dark/Light ??*/}
          {/* <div className="flex min-h-0 flex-1 flex-col bg-gray-900"> */}
          <div className="flex min-h-0 flex-1 flex-col bg-gray-800">
            <div className="flex flex-1 flex-col overflow-y-auto pt-5 pb-4">
              <div className="flex flex-shrink-0 items-center px-4">
                {/* <img
                  className="h-8 w-auto"
                  src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=500"
                  alt="Your Company"
                /> */}
                <h1 className="text-white text-2xl">TerraScape AI</h1>
              </div>
              <nav className="mt-5 flex-1 space-y-1 px-2">
                {navigation.map((item) => (
                  <Link
                    key={item.name}
                    to={item.to}
                    onClick={() => setCurrentMenuItem(item.name)}
                    className={classNames(
                      item.current
                        ? // ? "bg-gray-700 text-white"
                          "bg-gray-900 text-white"
                        : "text-gray-300 hover:bg-gray-900 hover:text-white",
                      "group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                    )}
                  >
                    <item.icon
                      className={classNames(
                        item.current
                          ? "text-gray-300"
                          : "text-gray-400 group-hover:text-gray-300",
                        "mr-3 flex-shrink-0 h-6 w-6"
                      )}
                      aria-hidden="true"
                    />
                    {item.name}
                  </Link>
                ))}
              </nav>
            </div>
            {/* <div className="flex flex-shrink-0 bg-gray-800 p-4"> */}
            <div className="flex flex-shrink-0 bg-gray-900 p-4">
              <button
                // to="/account"
                onClick={() => {
                  navigate(`/account`);
                  setCurrentMenuItem("Account");
                }}
                className="group block w-full flex-shrink-0"
              >
                <div className="flex items-center text-left">
                  <div>
                    {/* <img
                      className="inline-block h-9 w-9 rounded-full"
                      src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                      alt=""
                    /> */}
                  </div>
                  <div className="ml-3">
                    <p className="text-sm font-medium text-white group-hover:text-gray-300">
                      {/* {Auth.user?.attributes.Username} */}
                      {/* Test User */}

                      {user?.username || "No Username"}
                    </p>
                    {/* <p className="text-xs font-medium text-gray-300 group-hover:text-gray-200 truncate max-w-[150px]">
                    

                      {user?.email || "No Email"}
                    </p> */}
                  </div>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
