//  Data Intake view
import React, { useEffect, useState, Fragment } from "react";
// import { Disclosure, Menu, Transition, Dialog } from "@headlessui/react";
import axios from "axios";
import { Route, Routes, useNavigate } from "react-router";
import {
  Typography,
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
} from "@mui/material";

import DeleteIcon from "@mui/icons-material/Delete";

//  Notification Context
import { useNotification } from "../components/NotificationContext";

// Import env variables
const URL = process.env.REACT_APP_API_URL;

function DataIntakeView({
  currentMenuItem,
  setCurrentMenuItem,
  projectId,
  setProjectId,
  reportId,
  setReportId,
  user,
}) {
  const navigate = useNavigate();

  const [files, setFiles] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [responses, setResponses] = useState([]);
  const [status, setStatus] = useState("");

  const fileInputRef = React.useRef();
  const [currentFile, setCurrentFile] = useState(0);
  const [numFiles, setNumFiles] = useState(0);
  const [isAnalyzing, setIsAnalyzing] = useState(false);

  // notification context
  // const { addNotification } = useNotification();
  const { addNotification, removeNotification } = useNotification();

  const onFilesChange = (e) => {
    // Convert the new FileList to an array and concatenate with the existing files
    const newFiles = Array.from(e.target.files);
    setFiles((prevFiles) => [...prevFiles, ...newFiles]);
    setNumFiles((prevNumFiles) => prevNumFiles + newFiles.length);
  };

  const clearFiles = () => {
    setFiles([]);
    setNumFiles(0);
    setUploadedFiles([]);
    setCurrentFile(0);
    setStatus("");

    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const onUpload = async () => {
    const formData = new FormData();
    let invalidFiles = [];

    for (let i = 0; i < files.length; i++) {
      setCurrentFile(i + 1);
      setStatus(`Uploading file ${i + 1} of ${files.length}...`);
      formData.append("files", files[i]);
    }

    if (invalidFiles.length > 0) {
      alert(
        `The upload process encountered an issue:\n\n` +
          `Click "OK" to clear the current selection and try again.`
      );
      setStatus("Error: Invalid file(s) selected.");
      clearFiles();
      return;
    }

    const response = await axios.post(`${URL}/upload/`, formData, {
      // withCredentials: true,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });

    const data = await response.data;
    console.log(data);
    if (data.task_id) {
      await pollStatus(data.task_id);
    }
  };

  async function checkStatus(taskId) {
    const response = await fetch(`/status/${taskId}`);
    const data = await response.json();
    return data.status; // Ensure this matches the structure sent by the server
  }

  async function pollStatus(taskId) {
    let attempts = 0;
    let status = await checkStatus(taskId);
    let notificationId = addNotification(
      "Processing...",
      "Your files are currently being processed.",
      "info"
    );

    while (status === "Processing" || status === "Pending") {
      if (attempts > 20) {
        removeNotification(notificationId);
        addNotification(
          "Processing Timeout",
          "Processing has timed out. Please try again.",
          "error"
        );
        break;
      }
      await new Promise((resolve) => setTimeout(resolve, 5000));
      status = await checkStatus(taskId);
      attempts++;
    }

    if (["Splitting Completed", "Completed", "Failed"].includes(status)) {
      removeNotification(notificationId);

      if (status === "Completed" || status === "Splitting Completed") {
        addNotification(
          "Processing completed successfully.",
          "Your files have been processed.",
          "success"
        );
      } else {
        addNotification(
          "Processing failed.",
          "There was an error processing your files.",
          "error"
        );
      }
    }
  }

  const removeFile = (fileToRemove) => {
    // Convert FileList to an array, filter out the file to remove, and update state
    const updatedFiles = Array.from(files).filter(
      (file) => file !== fileToRemove
    );
    setFiles(updatedFiles);
    setNumFiles(updatedFiles.length); // Update the numFiles state
  };

  return (
    <div>
      <main className="flex-1">
        <div className="py-6">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
            <div className="py-4">
              <div>
                <h2 className="text-lg leading-6 font-medium text-gray-900">
                  Add Documents
                </h2>
              </div>
            </div>

            <div className="py-4 border border-gray-200 rounded-lg p-4">
              <div>
                <h2 className="text-lg leading-6 font-medium text-gray-900">
                  Document Upload
                </h2>
                <div className="mt-2">
                  <h3 className="text-sm leading-6 font-medium text-gray-500">
                    Instructions for Uploading:
                  </h3>
                  <ul className="list-disc list-inside text-sm text-gray-500">
                    <li>Select the documents you wish to upload.</li>
                    <li>
                      Click on the 'Upload' button to start the upload process.
                    </li>
                    <li>
                      Please note that the processing of documents may take a
                      few minutes.
                    </li>
                    <li>
                      Do not attempt to use the documents immediately after
                      uploading.{" "}
                      <span className="font-bold text-red-600 bg-red-100 p-1 rounded">
                        Wait for a few minutes
                      </span>{" "}
                      to ensure the processing is complete.
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            {/* Upload New Docs? */}
            {numFiles === 0 && (
              <div className="flex flex-col space-y-2 justify-start">
                <label
                  htmlFor="file-upload"
                  className="bg-indigo-600 hover:bg-indigo-800 text-white font-semibold py-1 px-2 rounded text-sm transition duration-300 ease-in-out w-48 text-center my-4 hover:cursor-pointer active:bg-indigo-800"
                >
                  Select Documents
                </label>
                <input
                  type="file"
                  multiple
                  onChange={onFilesChange}
                  ref={fileInputRef}
                  className="hidden"
                  id="file-upload"
                />
              </div>
            )}

            {numFiles > 0 && !isAnalyzing && (
              <>
                <TableContainer
                  component={Paper}
                  sx={{ border: "1px solid #e0e0e0", borderRadius: "4px" }}
                >
                  <Table className="w-full" aria-label="simple table">
                    <TableBody>
                      {Array.from(files).map((file, index) => (
                        <TableRow key={index}>
                          <TableCell component="th" scope="row">
                            {file.name}
                          </TableCell>
                          <TableCell align="right">
                            <IconButton onClick={() => removeFile(file)}>
                              <DeleteIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>

                <div className="flex justify-between mt-4">
                  <div className="flex items-center space-x-2">
                    <div className="flex items-center space-x-2">
                      <button
                        className={`bg-indigo-600 hover:bg-indigo-800 text-white font-semibold py-1 px-2 rounded text-sm transition duration-300 ease-in-out self-center w-36 text-center my-4 ${
                          numFiles === 0 ? "opacity-50 cursor-not-allowed" : ""
                        }`}
                        onClick={async () => {
                          setIsAnalyzing(true);
                          setStatus("Uploading...");
                          await onUpload();
                        }}
                        disabled={numFiles === 0}
                      >
                        Upload
                      </button>
                    </div>

                    <div className="flex items-center space-x-2">
                      {/* "Add More Documents" button */}
                      <label
                        htmlFor="file-add"
                        className="bg-green-600 hover:bg-green-800 text-white font-semibold py-1 px-2 rounded text-sm transition duration-300 ease-in-out text-center my-4 hover:cursor-pointer active:bg-green-800"
                      >
                        Add More Documents
                      </label>
                      <input
                        type="file"
                        multiple
                        onChange={onFilesChange}
                        className="hidden"
                        id="file-add"
                      />
                    </div>
                  </div>

                  <div>
                    {/* "Clear" button */}
                    {numFiles > 0 && (
                      <button
                        // className="p-2 bg-red-500 text-white rounded"
                        className="bg-red-500 hover:bg-red-700 text-white font-semibold py-1 px-2 rounded text-sm transition duration-300 ease-in-out self-center w-36 text-center my-4"
                        onClick={clearFiles}
                      >
                        Clear
                      </button>
                    )}
                  </div>
                </div>
              </>
            )}

            {isAnalyzing && (
              <div className="flex flex-col justify-center items-center">
                {/* <CircularProgress />
                <Typography variant="body1" className="ml-2">
                  Uploading...
                </Typography> */}

                <Typography variant="body2" className="mt-2 text-center">
                  Please note that documents may take a few minutes to process.
                  <br />
                  Do not attempt to use the documents immediately after
                  uploading.
                  <br />
                  Wait for a few minutes to ensure the processing is complete.
                </Typography>
              </div>
            )}
          </div>
        </div>
      </main>
    </div>
  );
}

export default DataIntakeView;
