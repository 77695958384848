// import React, {
//   useEffect,
//   useState,
//   Fragment,
//   useMemo,
//   useCallback,
// } from "react";
// import { Disclosure, Menu, Transition, Dialog } from "@headlessui/react";
// import axios from "axios";
// import { Route, Routes, useNavigate } from "react-router";
// import { v4 as uuid } from "uuid";

// import { Tabs, Tab } from "@mui/material";
// import {
//   Select,
//   MenuItem,
//   Grid,
//   Paper,
//   Box,
//   Typography,
//   InputLabel,
//   FormControl,
// } from "@mui/material";

// // Import env variables
// const URL = process.env.REACT_APP_API_URL;

// function ManageKeywordView({
//   currentMenuItem,
//   setCurrentMenuItem,
//   user,
//   setUser,
//   isAuthenticated,
//   setIsAuthenticated,
//   projectId,
//   setProjectId,
// }) {
//   // Keyword Variables (Name, Documents, etc.)
//   const [keywordName, setKeywordName] = useState("");

//   // New Keyword Form (Name, Documents, etc.)
//   const [newKeyword, setNewKeyword] = useState(""); // 'newKeyword' is the new keyword object

//   //  Keyword Collection
//   const [keywordCollection, setKeywordCollection] = useState([]);

//   //  keyword_type
//   const [keywordType, setKeywordType] = useState("");

// // // Get all keywords for the current user
// const [keywords, setKeywords] = useState([]);
// const [selectedKeyword, setSelectedKeyword] = useState(null);

// // Update 'getKeywords' function
// const getKeywords = async () => {
//   const response = await axios.get(`${URL}/keyword/user/`, {
//     params: { user_id: user.user_id },
//     withCredentials: true, // Include cookies with the request
//   });

//   const data = await response.data;
//   console.log(data);
//   setKeywords(data); // Set 'keywords' to the array of keywords
// };

// useEffect(() => {
//   getKeywords();
// }, []);

//   const handleKeywordChange = (event) => {
//     const selectedKeyword = keywords.find(
//       (keyword) => keyword.keyword_id === event.target.value
//     );
//     if (selectedKeyword) {
//       setSelectedKeyword(selectedKeyword); // Set 'selectedKeyword' to the selected keyword
//       setKeywordName(selectedKeyword.title);
//       setKeywordType(selectedKeyword.keyword_type);
//       setKeywordCollection(selectedKeyword.keyword_collection);
//     } else {
//       setSelectedKeyword(null);
//       setKeywordName("");
//       setKeywordType("");
//       setKeywordCollection([]); // Set to empty array
//     }
//     setEditingKeyword(false);
//   };

//   // / Create a new keyword
//   const createKeyword = async () => {
//     try {
//       const response = await axios.post(
//         `${URL}/keyword/`,
//         {
//           user_id: user.user_id,
//           title: keywordName,
//           keyword_type: keywordType,
//           keyword_collection: keywordCollection, // Send as array
//           privacy: "private",
//         },
//         { withCredentials: true }
//       );

//       const data = await response.data;
//       console.log(data);

//       // Update state or do something else with 'data' here
//     } catch (error) {
//       console.error("Error creating keyword:", error);
//       // Handle error here
//     }
//   };

//   const deleteKeyword = async () => {
//     if (!selectedKeyword) {
//       console.error("No keyword selected");
//       return;
//     }

//     try {
//       const response = await axios.delete(
//         `${URL}/keyword/`,
//         {
//           data: selectedKeyword.keyword_id, // Send keyword_id as a string
//           headers: { "Content-Type": "application/json" },
//         },
//         { withCredentials: true }
//       );

//       const data = await response.data;
//       console.log(data);

//       // Update state or do something else with 'data' here
//       // For example, you might want to remove the deleted keyword from 'keywords'
//       setKeywords(
//         keywords.filter(
//           (keyword) => keyword.keyword_id !== selectedKeyword.keyword_id
//         )
//       );

//       // Clear the selected keyword
//       setSelectedKeyword(null);
//       setKeywordName("");
//       setKeywordType("");
//       setKeywordCollection([]); // Set to empty array
//     } catch (error) {
//       console.error("Error deleting keyword:", error);
//       // Handle error here
//     }
//   };

//   const updateKeyword = async () => {
//     if (!selectedKeyword) {
//       console.error("No keyword selected");
//       return;
//     }

//     try {
//       await axios.put(
//         `${URL}/keyword/`,
//         {
//           keyword_id: selectedKeyword.keyword_id,
//           user_id: user.user_id,
//           title: keywordName,
//           keyword_type: keywordType,
//           keyword_collection: keywordCollection, // Send as array
//           privacy: "private",
//         },
//         { withCredentials: true }
//       );

//       // Fetch the updated list of keywords from the server
//       await getKeywords();

//       setEditingKeyword(false);
//       setSelectedKeyword(null);
//       setKeywordName("");
//       setKeywordType("");
//       setKeywordCollection([""]);
//     } catch (error) {
//       console.error("Error updating keyword:", error);
//       // Handle error here
//     }
//   };

//   // Reset the form
//   const resetForm = () => {
//     setSelectedKeyword(null);
//     setKeywordName("");
//     setKeywordType("");
//     setKeywordCollection([""]);
//     // setIsEditing(true);
//     setEditingKeyword(true);
//   };

//   // Handle new keyword
//   const handleNewKeyword = () => {
//     resetForm();
//   };

//   const handleSaveKeyword = async () => {
//     await createKeyword();
//     await getKeywords();
//   };

//   const handleCancelKeyword = async () => {
//     // return to previous state
//     setSelectedKeyword(null);
//     setKeywordName("");
//     setKeywordType("");
//     setKeywordCollection([""]);
//     setEditingKeyword(false);
//     setCreatingKeyword(false);
//   };

//   const handleKeywordCollectionChange = (index, event) => {
//     const values = [...keywordCollection];
//     values[index] = event.target.value;
//     setKeywordCollection(values);
//   };

//   const handleAddKeyword = () => {
//     setKeywordCollection([...keywordCollection, ""]);
//   };

//   const handleRemoveKeyword = (index) => {
//     const values = [...keywordCollection];
//     values.splice(index, 1);
//     setKeywordCollection(values);
//   };

//   const [creatingKeyword, setCreatingKeyword] = useState(false);
//   const [editingKeyword, setEditingKeyword] = useState(false);

//   return (
//     <div>
//       <div className="mt-4">
//         <h3 className="text-lg leading-6 font-medium text-gray-900">
//           Keywords
//         </h3>
//         <p className="mt-1 text-sm text-gray-500">
//           Create a new keyword or view existing keywords.
//         </p>

//         {/* Create a new keyword */}
//         <div className="mt-4 flex flex-row space-x-4 items-center">
//           <div>
//             <button
//               type="button"
//               className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 w-36"
//               onClick={handleNewKeyword}
//             >
//               Create New +
//             </button>
//           </div>

//           <select
//             id="viewKeywords"
//             name="viewKeywords"
//             onChange={handleKeywordChange}
//             value={selectedKeyword ? selectedKeyword.keyword_id : ""} // use keyword_id as value
//             className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md disabled:opacity-50 disabled:bg-gray-200 disabled:cursor-not-allowed disabled:border-gray-300"
//             disabled={editingKeyword}
//           >
//             <option value="">Select a Keyword</option>
//             {keywords.map((keyword) => (
//               <option key={keyword.keyword_id} value={keyword.keyword_id}>
//                 {keyword.title}
//               </option>
//             ))}
//           </select>
//         </div>

//         <button
//           type="button"
//           onClick={() => {
//             setEditingKeyword(true);
//           }}
//           className="border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-700 mt-2"
//         >
//           Edit
//         </button>

//         {/* View the selected keyword as a form */}
//         <div className="mt-4">
//           <label
//             htmlFor="keywordName"
//             className="block text-sm font-medium text-gray-700"
//           >
//             Keyword Name Group
//           </label>
//           <input
//             type="text"
//             name="keywordName"
//             id="keywordName"
//             placeholder="Keyword Name"
//             value={keywordName}
//             onChange={(e) => setKeywordName(e.target.value)}
//             className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md disabled:opacity-50 disabled:bg-gray-200 disabled:cursor-not-allowed disabled:border-gray-300"
//             // disabled={!isEditing}
//             disabled={!editingKeyword}
//           />
//         </div>
//         <div className="mt-4">
//           <label
//             htmlFor="keywordType"
//             className="block text-sm font-medium text-gray-700"
//           >
//             Keyword Type
//           </label>
//           <input
//             type="text"
//             name="keywordType"
//             id="keywordType"
//             placeholder="Keyword Type"
//             value={keywordType}
//             onChange={(e) => setKeywordType(e.target.value)}
//             className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md disabled:opacity-50 disabled:bg-gray-200 disabled:cursor-not-allowed disabled:border-gray-300"
//             disabled={!editingKeyword}
//           />
//         </div>
//         <div className={`mt-4 ${editingKeyword ? "block" : "hidden"}`}>
//           <label
//             htmlFor="keywordCollection"
//             className="block text-sm font-medium text-gray-700"
//           >
//             Keyword Collection
//           </label>

//           {keywordCollection.map((keyword, index) => (
//             <div
//               key={index}
//               className="flex flex-row space-x-4 items-center mt-2"
//             >
//               <input
//                 type="text"
//                 value={keyword}
//                 onChange={(event) =>
//                   handleKeywordCollectionChange(index, event)
//                 }
//                 disabled={!editingKeyword}
//                 className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md disabled:opacity-50 disabled:bg-gray-200 disabled:cursor-not-allowed disabled:border-gray-300"
//               />
//               <button type="button" onClick={() => handleRemoveKeyword(index)}>
//                 Remove
//               </button>
//             </div>
//           ))}
//           <button
//             type="button"
//             onClick={handleAddKeyword}
//             disabled={!editingKeyword}
//             // not visible if not editing
//             className={`inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-black bg-gray-200 hover:bg-gray-300 mt-2 ${
//               editingKeyword ? "block" : "hidden"
//             }`}
//           >
//             {/* Plus Icon */}
//             Add New Keyword
//           </button>
//         </div>

//         {/* Save or Cancel the keyword */}
//         <div className="mt-4">
//           {/* Save edits to keyword */}
//           <button
//             type="button"
//             onClick={updateKeyword}
//             // show this button if editing a keyword
//             className={`inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 ${
//               editingKeyword || creatingKeyword ? "block" : "hidden"
//             }`}
//           >
//             Save Changes
//           </button>

//           <button
//             type="button"
//             onClick={handleSaveKeyword}
//             // show this button if creating a keyword
//             className={`inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 ${
//               editingKeyword || creatingKeyword ? "block" : "hidden"
//             }`}
//           >
//             Save New Keyword
//           </button>

//           {/* Delete the keyword */}

//           <button
//             type="button"
//             onClick={deleteKeyword}
//             // show this button if editing a keyword
//             className={`inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 ${
//               editingKeyword ? "block" : "hidden"
//             }`}
//           >
//             Delete Keyword
//           </button>

//           <button
//             type="button"
//             onClick={handleCancelKeyword}
//             // show this button if creating a keyword
//             className={`inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 ${
//               editingKeyword || creatingKeyword ? "block" : "hidden"
//             }`}
//           >
//             Cancel New Keyword
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default ManageKeywordView;

import React, { useState, useEffect } from "react";
import axios from "axios";

const WORKFLOW_STATE = {
  VIEWING: "viewing",
  CREATING: "creating",
  EDITING: "editing",
};

// Import env variables
const URL = process.env.REACT_APP_API_URL;

function ManageKeywordView({ user }) {
  const [workflowState, setWorkflowState] = useState(WORKFLOW_STATE.VIEWING);
  const [keywords, setKeywords] = useState([]);
  const [selectedKeyword, setSelectedKeyword] = useState(null);
  const [keywordName, setKeywordName] = useState("");
  const [keywordType, setKeywordType] = useState("");
  const [keywordCollection, setKeywordCollection] = useState([]);

  // Fetch keywords from the server
  const getKeywords = async () => {
    try {
      const response = await axios.get(`${URL}/keyword/user/`, {
        params: { user_id: user.user_id },
        // withCredentials: true,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      console.log(response.data);
      // setKeywords(response.data);
      const filteredKeywords = response.data.filter(
        (keyword) => keyword.title !== "Risk Analysis Keywords"
      );
      setKeywords(filteredKeywords);
    } catch (error) {
      console.error("Error fetching keywords:", error);
    }
  };

  useEffect(() => {
    getKeywords();
  }, []);

  const handleKeywordChange = (event) => {
    const selected = keywords.find(
      (keyword) => keyword.keyword_id === event.target.value
    );
    if (selected) {
      setSelectedKeyword(selected);
      setKeywordName(selected.title);
      setKeywordType(selected.keyword_type);
      setKeywordCollection(selected.keyword_collection);
      setWorkflowState(WORKFLOW_STATE.EDITING);
    } else {
      resetForm();
    }
  };

  const resetForm = () => {
    setSelectedKeyword(null);
    setKeywordName("");
    setKeywordType("");
    setKeywordCollection([]);
    setWorkflowState(WORKFLOW_STATE.VIEWING);
  };

  const createOrUpdateKeyword = async (isCreating) => {
    const endpoint = `${URL}/keyword/`;
    const method = isCreating ? "post" : "put";

    const data = {
      keyword_id: isCreating ? undefined : selectedKeyword.keyword_id,
      user_id: user.user_id,
      title: keywordName,
      keyword_type: keywordType,
      keyword_collection: keywordCollection,
      privacy: "private",
    };

    try {
      const response = await axios({
        method: method,
        url: endpoint,
        data: data,
        // withCredentials: true,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });

      // handle response
      await getKeywords();
      resetForm();
    } catch (error) {
      console.error(
        `Error ${isCreating ? "creating" : "updating"} keyword:`,
        error
      );
    }
  };

  const deleteKeyword = async () => {
    if (!selectedKeyword) {
      console.error("No keyword selected");
      return;
    }

    try {
      await axios({
        method: "delete",
        url: `${URL}/keyword/`,
        data: {
          keyword_id: selectedKeyword.keyword_id,
        },
        // withCredentials: true,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      await getKeywords();
      resetForm();
    } catch (error) {
      console.error("Error deleting keyword:", error);
    }
  };

  const handleKeywordCollectionChange = (index, event) => {
    const newCollection = [...keywordCollection];
    newCollection[index] = event.target.value;
    setKeywordCollection(newCollection);
  };

  const handleAddKeyword = () => {
    setKeywordCollection([...keywordCollection, ""]);
  };

  const handleRemoveKeyword = (index) => {
    const newCollection = [...keywordCollection];
    newCollection.splice(index, 1);
    setKeywordCollection(newCollection);
  };

  return (
    <div className="mt-4">
      <div className="mt-4">
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          Keyword Search Groups
        </h3>
        <p className="mt-1 text-sm text-gray-500">
          Create a new keyword search group or view existing.
        </p>
        <div className="mt-4 flex flex-row space-x-4 items-center">
          <div>
            <button
              type="button"
              className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 w-36 disabled:opacity-60  disabled:cursor-not-allowed"
              onClick={() => setWorkflowState(WORKFLOW_STATE.CREATING)}
              disabled={workflowState === WORKFLOW_STATE.EDITING}
            >
              Create New +
            </button>
          </div>

          {/* Keyword Selection Dropdown */}
          <select
            id="viewKeywords"
            name="viewKeywords"
            onChange={handleKeywordChange}
            value={selectedKeyword ? selectedKeyword.keyword_id : ""}
            className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md disabled:opacity-50 disabled:bg-gray-200 disabled:cursor-not-allowed disabled:border-gray-300"
            disabled={workflowState !== WORKFLOW_STATE.VIEWING}
          >
            <option value="">Select a Keyword Group</option>
            {keywords.map((keyword) => (
              <option key={keyword.keyword_id} value={keyword.keyword_id}>
                {keyword.title}
              </option>
            ))}
          </select>
        </div>

        {/* Keyword Form */}
        <div className="mt-4">
          <label
            htmlFor="keywordName"
            className="block text-sm font-medium text-gray-700"
          >
            Keyword Search Group Name
          </label>
          <input
            type="text"
            name="keywordName"
            id="keywordName"
            placeholder="Your Keyword Search Group Name"
            value={keywordName}
            onChange={(e) => setKeywordName(e.target.value)}
            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md disabled:opacity-50 disabled:bg-gray-200 disabled:cursor-not-allowed disabled:border-gray-300"
            disabled={workflowState === WORKFLOW_STATE.VIEWING}
          />
        </div>

        {/* ... additional form fields for keywordType and keywordCollection here ... */}
        {/* Keyword Type Field */}
        <div className="mt-4">
          <label
            htmlFor="keywordType"
            className="block text-sm font-medium text-gray-700"
          >
            Keyword Search Group Type
          </label>
          <input
            type="text"
            name="keywordType"
            id="keywordType"
            placeholder="The type of keyword search group (e.g. Risk Analysis)"
            value={keywordType}
            onChange={(e) => setKeywordType(e.target.value)}
            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md disabled:opacity-50 disabled:bg-gray-200 disabled:cursor-not-allowed disabled:border-gray-300"
            disabled={workflowState === WORKFLOW_STATE.VIEWING}
          />
        </div>

        {/* Keyword Collection Field */}
        <div className="mt-4">
          {(workflowState === WORKFLOW_STATE.CREATING ||
            workflowState === WORKFLOW_STATE.EDITING) && (
            <>
              <label
                htmlFor="keywordCollection"
                className="block text-sm font-medium text-gray-700"
              >
                Keyword (Search phrase) Collection
              </label>
              {keywordCollection.map((keyword, index) => (
                <div key={index} className="flex items-center space-x-2 mt-2">
                  <input
                    type="text"
                    value={keyword}
                    onChange={(e) => handleKeywordCollectionChange(index, e)}
                    className="focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md disabled:opacity-50 disabled:bg-gray-200 disabled:cursor-not-allowed disabled:border-gray-300"
                    disabled={workflowState === WORKFLOW_STATE.VIEWING}
                    placeholder="Write a keyword here (e.g. risk or financial)"
                  />
                  <button
                    type="button"
                    onClick={() => handleRemoveKeyword(index)}
                    className="inline-flex items-center px-2 py-1 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-500 hover:bg-red-600"
                    disabled={workflowState === WORKFLOW_STATE.VIEWING}
                  >
                    Remove
                  </button>
                </div>
              ))}
              <button
                type="button"
                onClick={handleAddKeyword}
                className="mt-2 inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white s bg-indigo-600 hover:bg-indigo-700 w-36"
                disabled={workflowState === WORKFLOW_STATE.VIEWING}
              >
                Add Keyword +
              </button>
            </>
          )}
        </div>

        {/* Action Buttons */}
        <div className="mt-4 flex justify-between">
          {workflowState === WORKFLOW_STATE.CREATING && (
            <button
              type="button"
              className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700"
              onClick={() => createOrUpdateKeyword(true)}
            >
              Save Keyword Group
            </button>
          )}

          {workflowState === WORKFLOW_STATE.EDITING && (
            <button
              type="button"
              className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700"
              onClick={() => createOrUpdateKeyword(false)}
            >
              Save Changes
            </button>
          )}

          <div>
            {workflowState === WORKFLOW_STATE.EDITING && (
              <button
                type="button"
                className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 ml-2"
                onClick={deleteKeyword}
              >
                Delete Keyword
              </button>
            )}

            {(workflowState === WORKFLOW_STATE.CREATING ||
              workflowState === WORKFLOW_STATE.EDITING) && (
              <button
                type="button"
                className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-500 hover:bg-gray-600 ml-2"
                onClick={resetForm}
              >
                Cancel
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ManageKeywordView;
