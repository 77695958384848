// Import env variables
const URL = process.env.REACT_APP_API_URL;

export async function checkIfUserIsAuthenticated() {
  const token = localStorage.getItem("access_token"); // Retrieve the token from localStorage
  console.log("Token:", token);
  try {
    const response = await fetch(`${URL}/users/is-authenticated`, {
      headers: {
        Authorization: `Bearer ${token}`, // Include the token in the Authorization header
      },
    });

    if (response.ok) {
      const data = await response.json();
      console.log("Is authenticated:", data.isAuthenticated);
      return true;
    } else {
      console.log("response was not ok");
      console.log(response);
      return false;
    }
  } catch (error) {
    console.error("Failed to fetch:", error);
  }
}

// export async function checkIfUserIsAuthenticated() {
//   const token = localStorage.getItem("access_token");
//   try {
//     const response = await fetch(`${URL}/users/is-authenticated`, {
//       headers: {
//         Authorization: `Bearer ${token}`,
//       },
//     });

//     if (response.ok) {
//       const data = await response.json();
//       return true;
//     } else if (response.status === 401) {
//       // Check if redirect has already been attempted
//       const urlParams = new URLSearchParams(window.location.search);
//       if (!urlParams.has("redirected")) {
//         // Redirect to login page with a flag
//         window.location.href = `/login?redirected=true`;
//       }
//       return false;
//     } else {
//       console.log("response was not ok");
//       return false;
//     }
//   } catch (error) {
//     console.error("Failed to fetch:", error);
//   }
// }
