import React from "react";
import DashboardView from "../components/DashboardView";

function Dashboard({
  currentMenuItem,
  setCurrentMenuItem,
  user,
  projectId,
  setProjectId,
  reportId,
  setReportId,
}) {
  return (
    <div>
      <main className="flex-1">
        <div className="py-6">
          <DashboardView
            // setCurrentDocument={setCurrentDocument}
            currentMenuItem={currentMenuItem}
            setCurrentMenuItem={setCurrentMenuItem}
            user={user}
            projectId={projectId}
            setProjectId={setProjectId}
            reportId={reportId}
            setReportId={setReportId}
          />
        </div>
      </main>
    </div>
  );
}

export default Dashboard;
