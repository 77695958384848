import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../assets/logo.jpeg";

// Import env variables
const URL = process.env.REACT_APP_API_URL;

function Login({ setIsAuthenticated }) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  // Get a navigate function
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    const response = await fetch(`${URL}/token`, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: `username=${encodeURIComponent(
        username
      )}&password=${encodeURIComponent(password)}`,
      // credentials: "include", // Include cookies with the request
    });

    console.log(response); // Log the response

    // if (response.ok) {
    //   // The server responded with a success status
    //   console.log("Logged in");

    //   // Update the authenticated state
    //   setIsAuthenticated(true);

    //   // Navigate to the dashboard page
    //   navigate("/dashboard");
    // } else {
    //   setError("Invalid username or password");
    // }
    const data = await response.json(); // Assuming the server sends back JSON with the token

    console.log(data);
    // print token
    console.log(data.access_token);

    if (response.ok) {
      console.log("Logged in");
      localStorage.setItem("access_token", data.access_token); // Store the token in local storage
      setIsAuthenticated(true);
      navigate("/dashboard");
    } else {
      setError("Invalid username or password");
    }
  };

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
      {/* <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <img className="mx-auto h-100 w-auto" src={logo} alt="TerraScapeAI" />{" "}
        <h1 className="text-center text-4xl font-bold leading-9 tracking-tight text-gray-900 mt-4">
          Under Construction
        </h1>
        <h3 className="mt-10 text-center text-xl font-bold leading-9 tracking-tight text-gray-900">
          Sign in to your account
        </h3>
      </div> */}
      <div className="mx-auto max-w-2xl text-center">
        <img className="mx-auto h-100 w-auto" src={logo} alt="TerraScapeAI" />

        <h2 className="mt-12 text-xl font-bold tracking-tight text-gray-700 sm:text-2xl sm:leading-10 text-center">
          We Are Currently Under Construction
        </h2>
        <p className="mt-6 text-lg leading-8 text-gray-600">
          TerraScape AI specializes in Agentic AI Orchestration to support companies in the design and construction ecosystem. The platform uses multi-agent systems (MAS) that generate and execute code in real-time, interacting directly with software APIs to create customized workflows and integrate data across different platforms. This approach overcomes traditional technology limitations, such as rigid APIs and siloed data structures, enabling more dynamic and efficient project management. By employing code-literate agents, TerraScape’s platform automates tasks, optimizes resources, and adapts to the unique demands of each project. These agents work continuously to break down data silos, improve workflows, and support more informed decision-making. TerraScape AI's focus is on improving efficiency, sustainability, and adaptability in construction, helping companies better manage complexity and enhance project outcomes.
        </p>
      </div>

      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        {/* <form className="space-y-6" action="#" method="POST"> */}
        <h3 className="mt-10 text-center text-xl font-bold leading-9 tracking-tight text-gray-900">
          Restricted Access
        </h3>
        <form className="space-y-6" onSubmit={handleSubmit}>
          <div>
            <label
              htmlFor="username"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Username
            </label>
            <div className="mt-2">
              <input
                id="username"
                name="username"
                type="text"
                autoComplete="username"
                required
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </div>
          </div>

          <div>
            <div className="flex items-center justify-between">
              <label
                htmlFor="password"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Password
              </label>
              <div className="text-sm">
                {/* <a href="#" className="font-semibold text-indigo-600 hover:text-indigo-500">
                    Forgot password?
                  </a> */}
              </div>
            </div>
            <div className="mt-2">
              <input
                id="password"
                name="password"
                type="password"
                autoComplete="current-password"
                required
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
          </div>

          <div>
            <button
              type="submit"
              className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Sign in
            </button>

            {error && <p className="text-red-500 text-xs italic">{error}</p>}
          </div>
        </form>
      </div>
    </div>
  );
}

export default Login;
