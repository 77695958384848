import React, { useEffect, useState, Fragment } from "react";
import { Disclosure, Menu, Transition, Dialog } from "@headlessui/react";
import axios from "axios";
import { Route, Routes, useNavigate } from "react-router";
import { v4 as uuid } from "uuid";

import GlobalChat from "./GlobalChat";

function DashboardView({ currentMenuItem, setCurrentMenuItem, user }) {
  const navigate = useNavigate();

  return (
    <div>
      <main className="">
        <div className="flex flex-col h-screen">
          <div className="flex flex-col flex-grow overflow-hidden">
            <GlobalChat
              currentMenuItem={currentMenuItem}
              setCurrentMenuItem={setCurrentMenuItem}
              user={user}
              className="flex-grow overflow-hidden"
            />
          </div>
        </div>
      </main>
    </div>
  );
}

export default DashboardView;
