import logo from "./logo.svg";
import "./App.css";
import React, { useState, useEffect, Fragment } from "react";
import { Route, Routes } from "react-router";
import { Outlet } from "react-router-dom";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

// Pages
import Dashboard from "./pages/Dashboard";
import NewRisk from "./pages/NewRisk";
import Reports from "./pages/Reports";
import Document from "./pages/Document";
import Manage from "./pages/Manage";
import Account from "./pages/Account";
import Login from "./pages/Login";
import DataIntake from "./pages/DataIntake";

// Components
import Navigation from "./components/Navigation";
import Feedback from "./components/Feedback";

//Icons
import { Bars3Icon } from "@heroicons/react/24/outline";

// Auth
import { checkIfUserIsAuthenticated } from "./auth";

// Context
import { NotificationProvider } from "./components/NotificationContext";
import NotificationComponent from "./components/NotificationComponent";
import { useNotification } from "./components/NotificationContext";

import { jwtDecode } from "jwt-decode";

// Import env variables
const URL = process.env.REACT_APP_API_URL;

function App() {
  const navigate = useNavigate();
  // Open and close the mobile menu
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [currentMenuItem, setCurrentMenuItem] = useState("Project Chat");

  // Possible Approach (Global State -> Project ID)
  const [projectId, setProjectId] = useState("");
  const [reportId, setReportId] = useState("");

  // set current user
  const [user, setUser] = useState(null);

  // Check if user is authenticated
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    checkIfUserIsAuthenticated().then(setIsAuthenticated);
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      axios
        .get(`${URL}/users/me/`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        })
        .then((response) => {
          if (response.data) {
            setUser(response.data);
            console.log(response.data);
          } else {
            // No user data returned
            throw new Error("No user data");
          }
        })
        .catch((error) => {
          console.log(error);
          setIsAuthenticated(false); // Update authentication state
          navigate("/login"); // Redirect to login
        });
    } else {
      setUser(null);
      navigate("/login"); // Ensure redirection to login if not authenticated
    }
  }, [isAuthenticated, navigate]);

  useEffect(() => {
    console.log("isAuthenticated:", isAuthenticated); // Log the value of isAuthenticated
    if (!isAuthenticated) {
      navigate("/login");
    }
  }, [isAuthenticated, navigate]);

  useEffect(() => {
    const checkSession = () => {
      const token = localStorage.getItem("access_token");
      if (token) {
        const { exp } = jwtDecode(token); // Assuming jwt-decode is installed
        if (Date.now() >= exp * 1000) {
          alert("Your session has expired. Please log in again.");
          setIsAuthenticated(false);
          navigate("/login");
        }
      }
    };

    const interval = setInterval(checkSession, 5 * 60 * 1000); // Check every 5 minutes
    return () => clearInterval(interval);
  }, [navigate]);

  //  Notification Variables
  // const { notifications, removeNotification } = useNotification();

  return (
    <>
      <NotificationProvider>
        <NotificationComponent />
        <Routes>
          <Route
            path="*"
            element={
              isAuthenticated ? (
                <div>
                  <Navigation
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                    currentMenuItem={currentMenuItem}
                    setCurrentMenuItem={setCurrentMenuItem}
                    user={user}
                  />
                  {/* App Page Structure */}
                  <div className="flex flex-1 flex-col md:pl-48">
                    {/* Mobile Nav */}
                    <div className="sticky top-0 z-10 bg-gray-100 pl-1 pt-1 sm:pl-3 sm:pt-3 md:hidden">
                      <button
                        type="button"
                        className="-ml-0.5 -mt-0.5 inline-flex h-12 w-12 items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                        onClick={() => setSidebarOpen(true)}
                      >
                        <span className="sr-only">Open sidebar</span>
                        <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                    <Routes>
                      <Route
                        path="/dashboard"
                        element={
                          <Dashboard
                            currentMenuItem={currentMenuItem}
                            setCurrentMenuItem={setCurrentMenuItem}
                            user={user}
                          />
                        }
                      />
                      <Route
                        path="/data-intake"
                        element={
                          <DataIntake
                            currentMenuItem={currentMenuItem}
                            setCurrentMenuItem={setCurrentMenuItem}
                            projectId={projectId}
                            setProjectId={setProjectId}
                            reportId={reportId}
                            setReportId={setReportId}
                            user={user}
                          />
                        }
                      />

                      <Route
                        path="/new"
                        element={
                          <NewRisk
                            currentMenuItem={currentMenuItem}
                            setCurrentMenuItem={setCurrentMenuItem}
                            projectId={projectId}
                            setProjectId={setProjectId}
                            reportId={reportId}
                            setReportId={setReportId}
                            user={user}
                          />
                        }
                      />
                      <Route
                        path="/reports"
                        element={
                          <Reports
                            currentMenuItem={currentMenuItem}
                            setCurrentMenuItem={setCurrentMenuItem}
                            projectId={projectId}
                            setProjectId={setProjectId}
                            reportId={reportId}
                            setReportId={setReportId}
                            user={user}
                          />
                        }
                      />
                      <Route
                        path="/documents"
                        element={
                          <Document
                            currentMenuItem={currentMenuItem}
                            setCurrentMenuItem={setCurrentMenuItem}
                            user={user}
                            setUser={setUser}
                            isAuthenticated={isAuthenticated}
                            setIsAuthenticated={setIsAuthenticated}
                          />
                        }
                      />
                      <Route
                        path="/manage"
                        element={
                          <Manage
                            currentMenuItem={currentMenuItem}
                            setCurrentMenuItem={setCurrentMenuItem}
                            user={user}
                            setUser={setUser}
                            isAuthenticated={isAuthenticated}
                            setIsAuthenticated={setIsAuthenticated}
                            projectId={projectId}
                            setProjectId={setProjectId}
                          />
                        }
                      />
                      <Route
                        path="/account"
                        element={
                          <Account
                            currentMenuItem={currentMenuItem}
                            setCurrentMenuItem={setCurrentMenuItem}
                            user={user}
                            setUser={setUser}
                            isAuthenticated={isAuthenticated}
                            setIsAuthenticated={setIsAuthenticated}
                          />
                        }
                      />
                    </Routes>

                    <Feedback
                      currentMenuItem={currentMenuItem}
                      setCurrentMenuItem={setCurrentMenuItem}
                      user={user}
                      setUser={setUser}
                      isAuthenticated={isAuthenticated}
                      setIsAuthenticated={setIsAuthenticated}
                    />
                  </div>
                </div>
              ) : (
                <Login setIsAuthenticated={setIsAuthenticated} />
              )
            }
          />
        </Routes>
        <Outlet />
      </NotificationProvider>
    </>
  );
}

export default App;
