import React from "react";
import ManageView from "../components/ManageView";

function Manage({
  currentMenuItem,
  setCurrentMenuItem,
  user,
  setUser,
  isAuthenticated,
  setIsAuthenticated,
  projectId,
  setProjectId,
}) {
  return (
    <div>
      <main className="flex-1">
        <div className="py-6">
          <ManageView
            // setCurrentDocument={setCurrentDocument}
            currentMenuItem={currentMenuItem}
            setCurrentMenuItem={setCurrentMenuItem}
            user={user}
            setUser={setUser}
            isAuthenticated={isAuthenticated}
            setIsAuthenticated={setIsAuthenticated}
            projectId={projectId}
            setProjectId={setProjectId}
          />
        </div>
      </main>
    </div>
  );
}

export default Manage;
