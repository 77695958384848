import React from "react";
import AccountView from "../components/AccountView";
import Feedback from "../components/Feedback";

function Account({
  currentMenuItem,
  setCurrentMenuItem,
  user,
  setUser,
  isAuthenticated,
  setIsAuthenticated,
}) {
  return (
    <div>
      <main className="flex-1">
        <div className="py-6">
          <AccountView
            // setCurrentDocument={setCurrentDocument}
            currentMenuItem={currentMenuItem}
            setCurrentMenuItem={setCurrentMenuItem}
            user={user}
            setUser={setUser}
            isAuthenticated={isAuthenticated}
            setIsAuthenticated={setIsAuthenticated}
          />

          <Feedback
            currentMenuItem={currentMenuItem}
            setCurrentMenuItem={setCurrentMenuItem}
            user={user}
            setUser={setUser}
            isAuthenticated={isAuthenticated}
            setIsAuthenticated={setIsAuthenticated}
          />

          {/* /End replace */}
          {/* </div> */}
        </div>
      </main>
    </div>
  );
}

export default Account;
