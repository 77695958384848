import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router";

const URL = process.env.REACT_APP_API_URL;

const WORKFLOW_STATE = {
  VIEWING: "viewing",
  CREATING: "creating",
  EDITING: "editing",
};

function ManageCollectionView({ user, projectId, setProjectId }) {
  const [projects, setProjects] = useState([]);
  const [projectName, setProjectName] = useState("");
  const [documents, setDocuments] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [projectDocs, setProjectDocs] = useState([]);
  const [filteredDocs, setFilteredDocs] = useState([]);
  const [tempDocsToAdd, setTempDocsToAdd] = useState([]);
  const [tempDocsToRemove, setTempDocsToRemove] = useState([]);
  const [workflowState, setWorkflowState] = useState(WORKFLOW_STATE.VIEWING);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    fetchProjects();
    fetchDocuments();
  }, []);

  const fetchProjects = async () => {
    try {
      const response = await axios.get(`${URL}/project/user/${user.user_id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      const fetchedProjects = response.data.projects || [];
      setProjects(fetchedProjects);
      return fetchedProjects; // Return the fetched projects
    } catch (error) {
      console.error("Error fetching projects:", error);
      return []; // Return an empty array if there's an error
    }
  };

  const fetchDocuments = async (page = 1, limit = 10) => {
    if (!user || !user.user_id) {
      console.error("User or user_id is not defined");
      return;
    }

    try {
      const response = await axios.get(
        `${URL}/chunk/user/${user.user_id}?page=${page}&limit=${limit}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      );
      const documents = response.data.documents || [];
      const totalCount = response.data.totalCount || 0;
      const totalPages = Math.ceil(totalCount / limit);
      const groupedByDocId = documents.reduce((acc, doc) => {
        acc[doc.doc_id] = { ...doc, chunks: acc[doc.doc_id]?.chunks || [] };
        return acc;
      }, {});
      setDocuments(groupedByDocId);
      setFilteredDocs(groupedByDocId); // Set filteredDocs here
      setTotalPages(totalPages);
      setCurrentPage(page);
    } catch (error) {
      console.error("Failed to fetch documents:", error);
    }
  };

  // Added
  useEffect(() => {
    if (selectedProject) {
      fetchProjectDocs(selectedProject.project_id);
    }
  }, [selectedProject]);

  const fetchProjectDocs = async (projectId) => {
    try {
      const response = await axios.get(`${URL}/project/docs/`, {
        params: { project_id: projectId },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      console.log("Fetched project documents:", response.data.documents);
      setProjectDocs(response.data.documents || []);
    } catch (error) {
      console.error("Error fetching project documents:", error);
    }
  };

  const handleRemoveDocTemp = (docId) => {
    console.log("Removing document:", docId);
    setTempDocsToRemove((prevDocs) => {
      if (prevDocs.includes(docId)) {
        console.log("Document already in tempDocsToRemove, removing it");
        return prevDocs.filter((id) => id !== docId);
      } else {
        console.log("Adding new document to tempDocsToRemove");
        return [...prevDocs, docId];
      }
    });

    // Remove from tempDocsToAdd if it was there
    setTempDocsToAdd((prevDocs) => prevDocs.filter((id) => id !== docId));
  };

  const handleAddDocTemp = (docId) => {
    console.log("Adding document:", docId, "Type:", typeof docId);
    setTempDocsToAdd((prevDocs) => {
      if (prevDocs.includes(docId)) {
        console.log("Document already in tempDocsToAdd, removing it");
        return prevDocs.filter((id) => id !== docId);
      } else {
        console.log("Adding new document to tempDocsToAdd");
        return [...prevDocs, docId];
      }
    });
  };

  const handleProjectChange = (event) => {
    const foundProject = projects.find(
      (project) => project.project_id === event.target.value
    );
    if (foundProject) {
      setSelectedProject(foundProject);
      setProjectName(foundProject.name);
      setProjectId(foundProject.project_id);
      setWorkflowState(WORKFLOW_STATE.VIEWING);
    } else {
      resetForm();
    }
  };

  const resetForm = () => {
    setProjectName("");
    setSelectedProject(null);
    setProjectDocs([]);
    setFilteredDocs(documents);
    setTempDocsToAdd([]);
    setTempDocsToRemove([]);
    setWorkflowState(WORKFLOW_STATE.VIEWING);
  };

  useEffect(() => {
    console.log("Project Docs:", projectDocs);
    console.log("All Documents:", documents);

    if (selectedProject) {
      const projectDocIds = projectDocs;
      console.log("Project Doc IDs:", projectDocIds);

      const filtered = Object.entries(documents).reduce((acc, [docId, doc]) => {
        if (!projectDocIds.includes(docId)) {
          acc[docId] = doc;
        }
        return acc;
      }, {});

      console.log("Filtered Documents:", filtered);
      setFilteredDocs(filtered);
    } else {
      setFilteredDocs(documents);
      console.log("Filtered Documents: no project", documents);
    }
  }, [documents, projectDocs, selectedProject]);

  const createOrUpdateProject = async (isCreating) => {
    if (!projectName.trim()) {
      alert("Project name cannot be blank.");
      return;
    }

    const nameExists = projects.some((project) => {
      return isCreating
        ? project.name.toLowerCase() === projectName.trim().toLowerCase()
        : project.name.toLowerCase() === projectName.trim().toLowerCase() &&
            project.project_id !== selectedProject.project_id;
    });

    if (nameExists) {
      alert("A project with this name already exists.");
      return;
    }

    const updatedDocIds = isCreating
      ? tempDocsToAdd
      : [
          ...new Set([
            ...projectDocs.filter((docId) => !tempDocsToRemove.includes(docId)),
            ...tempDocsToAdd,
          ]),
        ];

    console.log("Updated document IDs:", updatedDocIds);

    // Filter out any invalid document IDs (like "0" or "1")
    const validDocIds = updatedDocIds.filter(
      (id) => typeof id === "string" && id.length > 1
    );

    console.log("Document IDs being sent:", validDocIds);

    const data = {
      project_id: selectedProject ? selectedProject.project_id : undefined,
      user_id: user.user_id,
      name: projectName,
      documents: validDocIds,
    };

    const method = isCreating ? "post" : "put";
    const endpoint = `${URL}/project/`;

    try {
      const response = await axios({
        method: method,
        url: endpoint,
        data: data,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      console.log("API Response:", response.data);
      await fetchProjects();

      // Update projectDocs with the new set of documents
      setProjectDocs(validDocIds);
      console.log("Updated projectDocs:", validDocIds);

      // Clear temporary changes
      setTempDocsToAdd([]);
      setTempDocsToRemove([]);

      // If creating a new project, update the selectedProject
      if (isCreating) {
        if (response.data.project) {
          setSelectedProject(response.data.project);
          // Refresh the project documents
          await fetchProjectDocs(response.data.project.project_id);
        } else {
          console.error("Created project data not found in response");
          // Fetch the latest projects to get the newly created project
          const updatedProjects = await fetchProjects();
          const newProject = updatedProjects[updatedProjects.length - 1];
          if (newProject) {
            setSelectedProject(newProject);
            await fetchProjectDocs(newProject.project_id);
          } else {
            console.error("Unable to find newly created project");
          }
        }
      } else {
        // Refresh the project documents for existing project
        await fetchProjectDocs(selectedProject.project_id);
      }

      resetForm();
      setWorkflowState(WORKFLOW_STATE.VIEWING);
    } catch (error) {
      console.error("API Error:", error);
      if (error.response) {
        console.error("Error Status:", error.response.status);
        console.error("Error Data:", error.response.data);
      }
    }
  };

  const deleteProject = async () => {
    if (!selectedProject) {
      console.error("No project selected");
      return;
    }

    try {
      await axios({
        method: "delete",
        url: `${URL}/project/`,
        data: {
          project_id: selectedProject.project_id,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      await fetchProjects();
      resetForm();
    } catch (error) {
      console.error("Error deleting project:", error);
    }
  };

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      fetchDocuments(newPage);
    }
  };

  useEffect(() => {
    console.log("projectDocs updated:", projectDocs);
    console.log("tempDocsToAdd updated:", tempDocsToAdd);
    console.log("tempDocsToRemove updated:", tempDocsToRemove);
  }, [projectDocs, tempDocsToAdd, tempDocsToRemove]);

  useEffect(() => {
    console.log("tempDocsToAdd changed:", tempDocsToAdd);
  }, [tempDocsToAdd]);

  useEffect(() => {
    console.log("tempDocsToRemove changed:", tempDocsToRemove);
  }, [tempDocsToRemove]);

  return (
    <div className="mt-4">
      <div className="mt-4">
        <p className="mt-1 text-sm text-gray-500">
          Create a new project or view existing projects.
        </p>

        <div className="mt-4 flex flex-row space-x-4 items-center">
          <div>
            <button
              type="button"
              className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 w-36"
              onClick={() => {
                resetForm();

                setWorkflowState(WORKFLOW_STATE.CREATING);
              }}
              disabled={workflowState === WORKFLOW_STATE.EDITING}
            >
              Create New +
            </button>
          </div>

          <select
            className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
            value={selectedProject ? selectedProject.project_id : ""}
            onChange={handleProjectChange}
          >
            <option value="">Select a project</option>
            {projects.map((project) => (
              <option key={project.project_id} value={project.project_id}>
                {project.name}
              </option>
            ))}
          </select>
        </div>

        {/* Project form inputs */}
        <div className="mt-4">
          <label
            htmlFor="project-name"
            className="block text-sm font-medium text-gray-700"
          >
            Project Name
          </label>
          <input
            type="text"
            name="project-name"
            id="project-name"
            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md disabled:opacity-50 disabled:bg-gray-200 disabled:cursor-not-allowed disabled:border-gray-300"
            placeholder="Your project name..."
            value={projectName}
            onChange={(e) => setProjectName(e.target.value)}
            disabled={workflowState === WORKFLOW_STATE.VIEWING}
          />
        </div>

        {/* Documents Sections */}
        <div className="mt-4 grid grid-cols-2 gap-4">
          {/* Documents in Project */}
          <div className="bg-light-blue-50 border border-gray-200 rounded-md p-4">
            <fieldset>
              <legend className="text-base font-semibold leading-6 text-gray-900">
                Documents in Current Project
              </legend>
              <div className="mt-4 border-b border-t border-gray-200">
                <div className="py-2 flex items-center justify-between">
                  <span className="font-medium text-gray-700">Title</span>
                  <span className="ml-3 font-medium text-gray-700">Remove</span>
                </div>
                {[...new Set([...projectDocs, ...tempDocsToAdd])].map(
                  (docId) => {
                    const doc = documents[docId];
                    return doc ? (
                      <div key={docId} className="flex items-center py-2 h-14">
                        <div className="flex-1 text-sm leading-6">
                          {doc.doc_title}
                        </div>
                        <div className="ml-3">
                          <input
                            id={`document-${docId}`}
                            name={`document-${docId}`}
                            type="checkbox"
                            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                            onChange={() => handleRemoveDocTemp(docId)}
                            disabled={workflowState !== WORKFLOW_STATE.EDITING}
                            checked={tempDocsToRemove.includes(docId)}
                          />
                        </div>
                      </div>
                    ) : null;
                  }
                )}
              </div>
            </fieldset>
          </div>

          {/* Documents Available */}
          <div className="bg-light-blue-50 border border-gray-200 rounded-md p-4">
            <fieldset>
              <legend className="text-base font-semibold leading-6 text-gray-900">
                Documents Available to Add to the Project
              </legend>
              <div className="mt-4 border-b border-t border-gray-200">
                <div className="py-2 flex items-center justify-between">
                  <span className="font-medium text-gray-700">Title</span>
                  <span className="ml-3 font-medium text-gray-700">Add</span>
                </div>
                {Object.entries(filteredDocs).map(([docId, doc]) => (
                  <div key={docId} className="flex items-center py-2 h-14">
                    <div className="flex-1 text-sm leading-6">
                      {doc.doc_title}
                    </div>
                    <div className="ml-3">
                      <input
                        id={`document-add-${docId}`}
                        name={`document-add-${docId}`}
                        type="checkbox"
                        className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        onChange={() => handleAddDocTemp(docId)}
                        disabled={
                          workflowState !== WORKFLOW_STATE.EDITING &&
                          workflowState !== WORKFLOW_STATE.CREATING
                        }
                        checked={tempDocsToAdd.includes(docId)}
                      />
                    </div>
                  </div>
                ))}
              </div>

              {/* Pagination controls */}
              <div className="mt-4 flex items-center justify-between">
                <button
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                  className="px-3 py-1 bg-indigo-600 text-white rounded disabled:bg-gray-300"
                >
                  Previous
                </button>
                <span>
                  Page {currentPage} of {totalPages}
                </span>
                <button
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage === totalPages}
                  className="px-3 py-1 bg-indigo-600 text-white rounded disabled:bg-gray-300"
                >
                  Next
                </button>
              </div>
            </fieldset>
          </div>
        </div>

        {/* Save and Delete buttons */}
        <div className="mt-4 flex justify-between w-full">
          {/* Left side container for Save Changes button */}
          <div>
            {workflowState === WORKFLOW_STATE.EDITING && (
              <button
                type="button"
                className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700"
                onClick={() => createOrUpdateProject(false)}
              >
                Save Changes
              </button>
            )}
          </div>

          {workflowState === WORKFLOW_STATE.CREATING && (
            <button
              type="button"
              className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700"
              onClick={() => createOrUpdateProject(true)}
            >
              Save New Project
            </button>
          )}

          {/* Right side container for Edit, Delete, and Cancel buttons */}
          <div className="ml-auto flex items-center">
            {selectedProject && workflowState === WORKFLOW_STATE.VIEWING && (
              <button
                type="button"
                className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-500 hover:bg-gray-600"
                onClick={() => setWorkflowState(WORKFLOW_STATE.EDITING)}
              >
                Edit Project
              </button>
            )}

            {workflowState === WORKFLOW_STATE.CREATING && (
              <button
                type="button"
                className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-500 hover:bg-gray-600"
                onClick={() => {
                  resetForm();
                  setWorkflowState(WORKFLOW_STATE.VIEWING);
                }}
              >
                Cancel Create
              </button>
            )}

            {workflowState === WORKFLOW_STATE.EDITING && (
              <>
                <button
                  type="button"
                  className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 ml-4"
                  onClick={() => {
                    const isConfirmed = window.confirm(
                      "Are you sure you want to delete this project?"
                    );
                    if (isConfirmed) {
                      deleteProject();
                    }
                  }}
                >
                  Delete Project
                </button>
                <button
                  type="button"
                  className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-500 hover:bg-gray-600 ml-2"
                  onClick={resetForm}
                >
                  Cancel
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ManageCollectionView;
