import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router";

const URL = process.env.REACT_APP_API_URL;

const WORKFLOW_STATE = {
  VIEWING: "viewing",
  CREATING: "creating",
  EDITING: "editing",
};

function ManageTemplateView({ user }) {
  const navigate = useNavigate();

  const [templates, setTemplates] = useState([]);
  const [templateName, setTemplateName] = useState("");
  const [templateText, setTemplateText] = useState("");
  const [templateType, setTemplateType] = useState("");
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [workflowState, setWorkflowState] = useState(WORKFLOW_STATE.VIEWING);

  useEffect(() => {
    getTemplates();
  }, []);

  const getTemplates = async () => {
    try {
      const response = await axios.get(`${URL}/template/user/`, {
        params: { user_id: user.user_id },
        // withCredentials: true,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      // setTemplates(response.data);
      const filteredTemplates = response.data.filter(
        (template) => template.title !== "Risk Analysis Template"
      );
      setTemplates(filteredTemplates);
    } catch (error) {
      console.error("Error fetching templates:", error);
    }
  };

  const parseDynamicFields = (templateText) => {
    // Find the JSON part within the template text
    const jsonResponseMatch = templateText.match(
      /\{\{\n\s+"RESPONSE": \[\n\s+\{\{\n([\s\S]*?)\n\s+\}\},/
    );
    if (jsonResponseMatch && jsonResponseMatch[1]) {
      try {
        // Reconstruct the JSON string and parse it
        const jsonResponseString = `{${jsonResponseMatch[1]}}`;
        const jsonResponse = JSON.parse(
          `{"responseContent": ${jsonResponseString}}`
        );

        // Convert the parsed JSON into dynamic fields
        const parsedFields = Object.entries(jsonResponse.responseContent).map(
          ([key, value]) => ({
            key,
            value,
          })
        );
        setDynamicFields(parsedFields);
      } catch (error) {
        console.error("Error parsing dynamic fields:", error);
        setDynamicFields([{ key: "", value: "" }]);
      }
    } else {
      setDynamicFields([{ key: "", value: "" }]);
    }
  };

  const handleTemplateChange = (event) => {
    const foundTemplate = templates.find(
      (template) => template.template_id === event.target.value
    );
    if (foundTemplate) {
      setSelectedTemplate(foundTemplate);
      setTemplateName(foundTemplate.title);
      setTemplateText(foundTemplate.template_text);
      setTemplateType(foundTemplate.template_type);
      setWorkflowState(WORKFLOW_STATE.EDITING);

      // Extract and set the userMessage
      const userMessageMatch = foundTemplate.template_text.match(
        /^(.*?)\{\{.*?"RESPONSE": \[/s
      );
      if (userMessageMatch && userMessageMatch[1]) {
        setUserMessage(userMessageMatch[1].trim());
      } else {
        setUserMessage("");
      }

      // Parse the dynamic fields from the template text
      parseDynamicFields(foundTemplate.template_text);
    } else {
      resetForm();
    }
  };

  const resetForm = () => {
    setTemplateName("");
    setTemplateText("");
    setTemplateType("");
    setUserMessage("");
    setSelectedTemplate(null);
    setWorkflowState(WORKFLOW_STATE.VIEWING);
    // reset fields
    setDynamicFields([{ key: "", value: "" }]);
  };

  const createOrUpdateTemplate = async (isCreating) => {
    // Check if {prompt} is included in userMessage
    if (!userMessage.includes("{prompt}")) {
      alert(
        "Please include the {prompt} in your template text section. This is needed to perform the keyword search."
      );
      return;
    }
    // Construct the JSON response from dynamic fields
    let jsonResponse;
    if (dynamicFields.length > 0) {
      const responseContent = dynamicFields.reduce((acc, field) => {
        acc[field.key] = field.value;
        return acc;
      }, {});
      jsonResponse = `{{\n    "RESPONSE": [\n        {{\n             ${JSON.stringify(
        responseContent,
        null,
        13
      ).slice(
        1,
        -1
      )}\n        }},\n        ...Continue for additional entries as needed\n    ]\n}}`;
    } else {
      jsonResponse = '{{\n    "RESPONSE": []\n}}';
    }

    // Construct the final template with userMessage and jsonResponse
    const finalTemplate = `${userMessage}\n\n${jsonResponse}`;

    // Append additional static text
    // const staticText = `\n\nIf no responses were identified, please output an empty JSON object:\n\n${jsonResponse}\n\n[IMPORTANT: only output the JSON structure, do not include additional text in your output]\n\n---------------------------------------------\n---------------------------------------------\nSTART KEYWORD SEARCH RESULTS:\n\n{context}\n\nEND KEYWORD SEARCH RESULTS:\n\n--------------------------------------------\n--------------------------------------------`;
    const staticText = `\n\nIf no responses were identified, please output an empty JSON object:\n\n "RESPONSE": []\n\n[IMPORTANT: only output the JSON structure, do not include additional text in your output]\n\n---------------------------------------------\n---------------------------------------------\nSTART KEYWORD SEARCH RESULTS:\n\n{context}\n\nEND KEYWORD SEARCH RESULTS:\n\n--------------------------------------------\n--------------------------------------------`;

    const finalOutput = `${finalTemplate}${staticText}`;

    // Prepare the data for the API call
    const endpoint = `${URL}/template/`;
    const method = isCreating ? "post" : "put";
    const data = {
      template_id: isCreating ? undefined : selectedTemplate.template_id,
      user_id: user.user_id,
      title: templateName,
      template_type: templateType,
      template_text: finalOutput,
      privacy: "private",
    };

    try {
      const response = await axios({
        method: method,
        url: endpoint,
        data: data,
        // withCredentials: true,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });

      // handle response
      await getTemplates();
      resetForm();
    } catch (error) {
      console.error(
        `Error ${isCreating ? "creating" : "updating"} template:`,
        error
      );
    }
  };

  const deleteTemplate = async () => {
    if (!selectedTemplate) {
      console.error("No template selected");
      return;
    }

    try {
      await axios({
        method: "delete",
        url: `${URL}/template/`,
        data: {
          template_id: selectedTemplate.template_id,
        },
        // headers: { "Content-Type": "application/json" },
        // withCredentials: true,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      await getTemplates();
      resetForm();
    } catch (error) {
      console.error("Error deleting template:", error);
    }
  };

  // PROMPT
  const [userMessage, setUserMessage] = useState("");
  const [dynamicFields, setDynamicFields] = useState([{ key: "", value: "" }]);

  const handleAddField = () => {
    setDynamicFields([...dynamicFields, { key: "", value: "" }]);
  };

  const handleRemoveField = (index) => {
    setDynamicFields(dynamicFields.filter((_, i) => i !== index));
  };

  const handleFieldChange = (index, type, value) => {
    const updatedFields = dynamicFields.map((field, i) => {
      if (i === index) {
        return { ...field, [type]: value };
      }
      return field;
    });
    setDynamicFields(updatedFields);
  };

  return (
    <div className="mt-4">
      <div className="mt-4">
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          Templates
        </h3>
        <p className="mt-1 text-sm text-gray-500">
          Create a new template or view existing templates.
        </p>
        <div className="mt-4 flex flex-row space-x-4 items-center">
          <div>
            <button
              type="button"
              className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 w-36 disabled:opacity-60  disabled:cursor-not-allowed"
              onClick={() => setWorkflowState(WORKFLOW_STATE.CREATING)}
              disabled={workflowState === WORKFLOW_STATE.EDITING}
            >
              Create New +
            </button>
          </div>

          <select
            id="viewTemplates"
            name="viewTemplates"
            className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md disabled:opacity-50 disabled:bg-gray-200 disabled:cursor-not-allowed disabled:border-gray-300"
            value={selectedTemplate ? selectedTemplate.template_id : ""}
            onChange={handleTemplateChange}
            disabled={workflowState !== WORKFLOW_STATE.VIEWING}
          >
            <option value="">Select a Template</option>
            {templates.map((template) => (
              <option key={template.template_id} value={template.template_id}>
                {template.title}
              </option>
            ))}
          </select>
        </div>

        <div className="mt-4">
          <label
            htmlFor="template-name"
            className="block text-sm font-medium text-gray-700"
          >
            Template Name
          </label>
          <input
            type="text"
            name="template-name"
            id="template-name"
            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md disabled:opacity-50 disabled:bg-gray-200 disabled:cursor-not-allowed disabled:border-gray-300"
            placeholder="Your template name..."
            value={templateName}
            onChange={(e) => setTemplateName(e.target.value)}
            disabled={workflowState === WORKFLOW_STATE.VIEWING}
          />
        </div>

        <div className="mt-4">
          <label
            htmlFor="template-type"
            className="block text-sm font-medium text-gray-700"
          >
            Template Type
          </label>
          <input
            type="text"
            name="template-type"
            id="template-type"
            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md disabled:opacity-50 disabled:bg-gray-200 disabled:cursor-not-allowed disabled:border-gray-300"
            placeholder="Your template type..."
            value={templateType}
            onChange={(e) => setTemplateType(e.target.value)}
            disabled={workflowState === WORKFLOW_STATE.VIEWING}
          />

          <div>
            <label
              htmlFor="template-text"
              className="block text-sm font-medium text-gray-700 mt-4"
            >
              Instructions for the model
              <span className="text-xs text-gray-500">
                {" "}
                (include {`"{prompt}"`} as the placeholder for your search
                word/phrase)
              </span>
            </label>
            <textarea
              id="template-text"
              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md disabled:opacity-50 disabled:bg-gray-200 disabled:cursor-not-allowed disabled:border-gray-300"
              rows={4}
              value={userMessage}
              onChange={(e) => setUserMessage(e.target.value)}
              placeholder="Type your message with {prompt} included. This will be replaced with the prompt from the keyword search words."
              disabled={workflowState === WORKFLOW_STATE.VIEWING}
            />

            {/* Dynamic Fields for Template */}
            {(workflowState === WORKFLOW_STATE.CREATING ||
              workflowState === WORKFLOW_STATE.EDITING) && (
              <>
                <div className="flex mt-4">
                  <div className="w-full">
                    <label className="block text-sm font-medium text-gray-700">
                      Field Name (Key)
                    </label>
                  </div>
                  <div className="w-full">
                    <label className="block text-sm font-medium text-gray-700">
                      Example Output (Value)
                    </label>
                  </div>
                </div>

                {dynamicFields.map((field, index) => (
                  <div key={index} className="flex items-center space-x-2 mt-2">
                    <div className="flex flex-col w-full">
                      <input
                        id={`fieldKey-${index}`}
                        type="text"
                        placeholder="Write a short name for the field name (e.g. 'Possible Risk', 'Risk Level', etc.)"
                        value={field.key}
                        onChange={(e) =>
                          handleFieldChange(index, "key", e.target.value)
                        }
                        className="focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>

                    <div className="flex flex-col w-full">
                      <input
                        id={`fieldValue-${index}`}
                        type="text"
                        placeholder="Write a short example of the output you expect for this field (e.g. 'After reviewing the results, I believe this is a high risk....')"
                        value={field.value}
                        onChange={(e) =>
                          handleFieldChange(index, "value", e.target.value)
                        }
                        className="focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>

                    <button
                      type="button"
                      onClick={() => handleRemoveField(index)}
                      // className="inline-flex items-center px-2 py-1 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-500 hover:bg-red-600"
                      className="inline-flex items-center px-2 py-1 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-500 hover:bg-red-600"
                    >
                      Remove
                    </button>
                  </div>
                ))}
                <button
                  type="button"
                  onClick={handleAddField}
                  className="mt-2 inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 w-36"
                >
                  Add Field +
                </button>
              </>
            )}
          </div>
        </div>

        <div className="mt-4 flex justify-between">
          {workflowState === WORKFLOW_STATE.CREATING && (
            <button
              type="button"
              className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700"
              onClick={() => {
                createOrUpdateTemplate(true, dynamicFields, templateText);
              }}
            >
              Save New Template
            </button>
          )}

          {workflowState === WORKFLOW_STATE.EDITING && (
            <button
              type="button"
              className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700"
              onClick={() => {
                createOrUpdateTemplate(false, dynamicFields, templateText);
              }}
            >
              Save Edits
            </button>
          )}

          <div>
            {workflowState === WORKFLOW_STATE.EDITING && (
              <button
                type="button"
                className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 ml-2"
                onClick={deleteTemplate}
              >
                Delete Template
              </button>
            )}

            {(workflowState === WORKFLOW_STATE.CREATING ||
              workflowState === WORKFLOW_STATE.EDITING) && (
              <button
                type="button"
                className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-500 hover:bg-gray-600 ml-2"
                onClick={resetForm}
              >
                Cancel
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ManageTemplateView;
