import React, {
  useEffect,
  useState,
  Fragment,
  useMemo,
  useCallback,
} from "react";
import { Disclosure, Menu, Transition, Dialog } from "@headlessui/react";
import axios from "axios";
import { Route, Routes, useNavigate } from "react-router";
import { v4 as uuid } from "uuid";

import { Tabs, Tab } from "@mui/material";
import {
  Select,
  MenuItem,
  Grid,
  Paper,
  Box,
  Typography,
  InputLabel,
  FormControl,
} from "@mui/material";

import ManageCollectionView from "./ManageCollectionView";
import ManageTemplateView from "./ManageTemplateView";
import ManageKeywordView from "./ManageKeywordView";

// Import env variables
const URL = process.env.REACT_APP_API_URL;

function ManageView({
  currentMenuItem,
  setCurrentMenuItem,
  user,
  setUser,
  isAuthenticated,
  setIsAuthenticated,
  projectId,
  setProjectId,
}) {
  const navigate = useNavigate();

  const [selectedCategoryIndex, setSelectedCategoryIndex] = useState(0);
  const [selectedCategory, setSelectedCategory] = useState("");

  // Keyword Variables (Name, Documents, etc.)
  // const [keywordName, setKeywordName] = useState("");
  // const [keyword, setKeyword] = useState(""); // 'keyword' is the keyword object

  const handleCategoryChange = (event, newValue) => {
    setSelectedCategory(event.target.value);
    setSelectedCategoryIndex(newValue); //Needed?
  };

  return (
    <div>
      <main className="flex-1">
        <div className="py-6">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
            {/* <h1 className="text-2xl font-semibold text-gray-900">New Risk</h1> */}
            <div className="py-4">
              <div>
                <h2 className="text-lg leading-6 font-medium text-gray-900">
                  Manage
                </h2>
              </div>
              {/* <div className="h-96 rounded-lg border-4 border-dashed border-gray-200" /> */}

              {/* Page must display projects, templates and keywords -> Use Tabs to select which category? */}
              {/* <Grid item xs={12}>
                <Tabs
                  value={selectedCategoryIndex}
                  onChange={handleCategoryChange}
                  variant="scrollable"
                  scrollButtons="auto"
                >
                  <Tab key={0} label={"Projects"} />
                  <Tab key={1} label={"Templates"} />
                  <Tab key={2} label={"Keywords"} />
                </Tabs>
              </Grid> */}

              {/* Display a form for the Project Workflow (Create new, add/delete documents, etc.) */}
              {/* only show if category */}
              {selectedCategoryIndex === 0 && (
                <ManageCollectionView
                  user={user}
                  projectId={projectId}
                  setProjectId={setProjectId}
                />
              )}

              {/* Display a form for the Template Workflow (Create new, add/delete documents, etc.) */}
              {/* only show if category */}
              {selectedCategoryIndex === 1 && (
                <ManageTemplateView
                  user={user}
                  projectId={projectId}
                  setProjectId={setProjectId}
                />
              )}

              {/* Display a form for the Keyword Workflow (Create new, add/delete documents, etc.) */}
              {/* only show if category */}
              {selectedCategoryIndex === 2 && (
                <ManageKeywordView
                  user={user}
                  projectId={projectId}
                  setProjectId={setProjectId}
                />
              )}
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default ManageView;
