import React, {
  useEffect,
  useState,
  Fragment,
  useRef,
  useCallback,
} from "react";
import { Disclosure, Menu, Transition, Dialog } from "@headlessui/react";
import axios from "axios";
import { Route, Routes, useNavigate } from "react-router";
import { v4 as uuid } from "uuid";
// import { ChevronUpIcon } from "@heroicons/react/solid";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
//  trahs icon
import TrashIcon from "@mui/icons-material/Delete";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import CircularProgress from "@mui/material/CircularProgress";

// Import env variables
const URL = process.env.REACT_APP_API_URL;

function DocumentView({
  currentMenuItem,
  setCurrentMenuItem,
  user,
  setUser,
  isAuthenticated,
  setIsAuthenticated,
}) {
  const navigate = useNavigate();

  // const [documents, setDocuments] = useState([]);

  // const fetchDocuments = async () => {
  //   if (!user || !user.user_id) {
  //     console.error("User or user_id is not defined");
  //     return;
  //   }

  //   try {
  //     const response = await axios.get(`${URL}/chunk/user/${user.user_id}`, {
  //       headers: {
  //         Authorization: `Bearer ${localStorage.getItem("access_token")}`,
  //       },
  //     });
  //     const documents = response.data || [];
  //     const groupedByDocId = documents.reduce((acc, doc) => {
  //       // Ensure each document entry has a placeholder for chunks
  //       acc[doc.doc_id] = { ...doc, chunks: acc[doc.doc_id]?.chunks || [] };
  //       return acc;
  //     }, {});
  //     setDocuments(groupedByDocId);
  //   } catch (error) {
  //     console.error("Failed to fetch documents:", error);
  //   }
  // };
  // NEW CODE
  const [documents, setDocuments] = useState({});
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [pageSize] = useState(10);

  const fetchDocuments = async (page = 1, limit = pageSize) => {
    if (!user || !user.user_id) {
      console.error("User or user_id is not defined");
      return;
    }

    setLoading(true);

    try {
      const response = await axios.get(
        `${URL}/chunk/user/${user.user_id}?page=${page}&limit=${limit}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      );
      const documents = response.data.documents || [];
      const totalCount = response.data.totalCount || 0;
      const totalPages = Math.ceil(totalCount / limit);
      const groupedByDocId = documents.reduce((acc, doc) => {
        acc[doc.doc_id] = { ...doc, chunks: [] }; // Initialize chunks as an empty array
        return acc;
      }, {});
      setDocuments(groupedByDocId);
      setTotalPages(totalPages);
      setCurrentPage(page);
    } catch (error) {
      console.error("Failed to fetch documents:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDocuments();
  }, []);

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      fetchDocuments(newPage);
    }
  };
  // END NEW CODE

  const [openDocId, setOpenDocId] = useState(null);
  const [fetchedChunks, setFetchedChunks] = useState({});
  const [isLoadingChunks, setIsLoadingChunks] = useState({});
  const [isDeleting, setIsDeleting] = useState(false);

  const fetchChunks = useCallback(
    async (docId) => {
      console.log(`Fetching chunks for docId: ${docId}`);
      setIsLoadingChunks((prev) => ({ ...prev, [docId]: true }));
      try {
        const response = await axios.get(`${URL}/chunk/doc_id/all/${docId}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        });
        console.log("Chunks fetched for docId:", docId, response.data);
        setFetchedChunks((prev) => ({ ...prev, [docId]: response.data }));
        return response.data;
      } catch (error) {
        console.error(`Failed to fetch chunks for document ${docId}:`, error);
        return [];
      } finally {
        setIsLoadingChunks((prev) => ({ ...prev, [docId]: false }));
      }
    },
    [URL]
  );

  const handleDisclosureToggle = useCallback(
    async (docId) => {
      console.log(`Toggling disclosure for docId: ${docId}`);
      if (openDocId === docId) {
        console.log(`Closing docId: ${docId}`);
        setOpenDocId(null);
      } else {
        console.log(`Opening docId: ${docId}`);
        setOpenDocId(docId);
        if (!fetchedChunks[docId]) {
          await fetchChunks(docId);
        }
      }
    },
    [openDocId, fetchedChunks, fetchChunks]
  );

  useEffect(() => {
    console.log("Current openDocId:", openDocId);
    console.log("Current fetchedChunks:", fetchedChunks);
  }, [openDocId, fetchedChunks]);

  const [open, setOpen] = useState(false);
  const cancelButtonRef = useRef(null);

  const [docForDelete, setDocForDelete] = useState("");
  const [docForDeleteTitle, setDocForDeleteTitle] = useState("");

  const deleteDocumentAndChunks = useCallback(
    async (docId) => {
      setIsDeleting(true);
      try {
        await axios.delete(`${URL}/chunk/doc_id/${docId}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        });
        console.log(`Document ${docId} and its chunks deleted successfully`);

        // Remove the deleted document from the state
        setDocuments((prevDocuments) => {
          const newDocuments = { ...prevDocuments };
          delete newDocuments[docId];
          return newDocuments;
        });

        // Remove the fetched chunks for this document
        setFetchedChunks((prevChunks) => {
          const newChunks = { ...prevChunks };
          delete newChunks[docId];
          return newChunks;
        });

        // If the deleted document was open, close it
        if (openDocId === docId) {
          setOpenDocId(null);
        }
      } catch (error) {
        console.error(`Failed to delete document ${docId}:`, error);
        // You might want to show an error message to the user here
      } finally {
        setIsDeleting(false);
        setOpen(false); // Close the confirmation dialog
      }
    },
    [URL, openDocId, setDocuments]
  );

  return (
    <div>
      <main className="flex-1">
        <div className="py-6">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
            <div className="py-4">
              <div>
                <h2 className="text-lg leading-6 font-medium text-gray-900">
                  Documents
                </h2>

                <div>
                  {Object.entries(documents).map(([docId, doc], index) => (
                    <div key={docId} className="mt-2">
                      <div
                        className="flex justify-between w-full px-4 py-2 text-sm font-medium text-left text-black bg-gray-100 rounded-lg hover:bg-gray-200 cursor-pointer"
                        onClick={() => handleDisclosureToggle(docId)}
                      >
                        <div className="flex items-center space-x-2">
                          <ExpandMoreIcon
                            className={`${
                              openDocId === docId ? "transform rotate-180" : ""
                            } w-5 h-5 text-black`}
                          />
                          <span>
                            {doc.doc_title || "No title available"} - {docId}
                          </span>
                        </div>
                        <TrashIcon
                          className="w-5 h-5 text-black cursor-pointer"
                          onClick={(e) => {
                            e.stopPropagation();
                            setDocForDeleteTitle(doc.doc_title);
                            setDocForDelete(docId);
                            setOpen(true);
                          }}
                        />
                      </div>
                      {openDocId === docId && (
                        <div className="px-4 pt-4 pb-2 text-sm text-gray-500">
                          {isLoadingChunks[docId] ? (
                            <div className="flex justify-center items-center h-20">
                              <CircularProgress />
                            </div>
                          ) : fetchedChunks[docId] &&
                            fetchedChunks[docId].length > 0 ? (
                            <table className="min-w-full divide-y divide-gray-200">
                              <thead>
                                <tr>
                                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Page #
                                  </th>
                                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Text
                                  </th>
                                </tr>
                              </thead>
                              <tbody className="bg-white divide-y divide-gray-200">
                                {fetchedChunks[docId].map((chunk, index) => (
                                  <tr key={index}>
                                    <td className="px-6 py-4 whitespace-wrap text-sm text-gray-900">
                                      Page # {chunk.metadata.page_number}
                                    </td>
                                    <td className="px-6 py-4 whitespace-wrap text-sm text-gray-900">
                                      {chunk.text}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          ) : (
                            <div>No chunks available for this document.</div>
                          )}
                        </div>
                      )}
                    </div>
                  ))}
                </div>

                <div className="flex justify-between mt-4">
                  {currentPage !== 1 && (
                    <button
                      onClick={() => handlePageChange(currentPage - 1)}
                      className="px-4 py-2 bg-gray-300 rounded"
                    >
                      Previous
                    </button>
                  )}
                  <span>
                    Page {currentPage} of {totalPages}
                  </span>
                  <button
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                    className="px-4 py-2 bg-gray-300 rounded"
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>

      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={setOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                      <ExclamationTriangleIcon
                        className="h-6 w-6 text-red-600"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-base font-semibold leading-6 text-gray-900"
                      >
                        Delete Document
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          Are you sure you want to delete this document? You
                          will not be able to recover it afterwards. This action
                          cannot be undone.
                        </p>
                        {/* Show document title for deletion abbrev if too long */}
                        <p className="text-sm text-gray-500 mt-2">
                          Document Title: <br />
                          <span className="font-semibold">
                            {docForDeleteTitle.length > 40
                              ? docForDeleteTitle.slice(0, 40) + "..."
                              : docForDeleteTitle}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                      onClick={() => {
                        deleteDocumentAndChunks(docForDelete);
                        setOpen(false);
                      }}
                      disabled={isDeleting}
                    >
                      {isDeleting ? "Deleting..." : "Delete"}
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                      onClick={() => setOpen(false)}
                      ref={cancelButtonRef}
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}

export default DocumentView;
