// NotificationComponent.js
import React from "react";
import { useNotification } from "./NotificationContext";
import NotificationPanel from "./NotificationPanel";

const NotificationComponent = () => {
  const { notifications, removeNotification } = useNotification();

  return (
    // <div
    //   aria-live="assertive"
    //   className="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6 justify-end"
    // >
    <div
      aria-live="assertive"
      className="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6 justify-end z-50"
    >
      {notifications.map((notification) => (
        <NotificationPanel
          key={notification.id}
          show={true}
          message={notification.message}
          onClose={() => removeNotification(notification.id)}
          type={notification.type}
        />
      ))}
    </div>
  );
};

export default NotificationComponent;
