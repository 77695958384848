import { useState } from "react";
import { usePopper } from "react-popper";
// import Head from "next/head";
import axios from "axios";

export default function Feedback({
  currentMenuItem,
  setCurrentMenuItem,
  projectId,
  setProjectId,
  reportId,
  setReportId,
  user,
}) {
  const [showWidget, setShowWidget] = useState(false);
  const [feedback, setFeedback] = useState("");
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [{ name: "offset", options: { offset: [10, 10] } }],
  });

  const [feedbackType, setFeedbackType] = useState("");
  const [text, setText] = useState("");
  // const [userId, setUserId] = useState(user.user_id || "");
  const [userId, setUserId] = useState(user ? user.user_id : "");

  // update user_id if user logs in
  if (user && user.user_id && user.user_id !== userId) {
    setUserId(user.user_id);
  } else if (!user && userId !== "") {
    setUserId("");
  }

  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [error, setError] = useState("");

  const URL = process.env.REACT_APP_API_URL;

  const handleFeedbackChange = (event) => {
    setText(event.target.value);
  };

  const handleFeedbackTypeChange = (event) => {
    setFeedbackType(event.target.value);
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    if (!feedbackType || feedbackType === "" || !text) {
      setError("All fields are required");
      return;
    }
    try {
      const response = await axios.post(
        `${URL}/feedback/`,
        {
          user_id: userId,
          feedback_type: feedbackType,
          text: text,
        },
        {
          // withCredentials: true, // Include cookies with the request
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      );
      console.log(response);
      setSubmitSuccess(true);
      setText("");
      setTimeout(() => {
        setShowWidget(false);
        setSubmitSuccess(false);
      }, 3000);
    } catch (error) {
      if (error.response) {
        console.error("Error response:", error.response);
      } else if (error.request) {
        console.error("No response received:", error.request);
      } else {
        console.error("Error setting up the request:", error.message);
      }
      console.error("Error config:", error.config);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center py-2">
      {currentMenuItem !== "Risk Reports" && (
        <button
          onClick={() => setShowWidget(true)}
          className="fixed right-4 bottom-4 p-2 rounded-l-lg rounded-t-lg bg-blue-700 hover:bg-blue-600 shadow-md hover:shadow-lg text-white"
          type="button"
          hidden={showWidget}
        >
          Feedback?
        </button>
      )}
      {showWidget && !submitSuccess && currentMenuItem !== "Risk Reports" && (
        <div
          className="bg-white p-2 rounded-md shadow-lg fixed bottom-4 right-4 flex flex-col border border-slate-300"
          style={{
            position: "fixed",
            bottom: "20px",
            right: "20px",
            zIndex: 1000,
          }}
        >
          <div className="flex flex-row justify-between items-start pb-4">
            <h1 className="text-2xl font-semibold text-gray-900">Feedback</h1>
            <button
              onClick={() => setShowWidget(false)}
              className=" hover:bg-gray-400 text-gray-300 font-bold w-6 h-6 flex items-center justify-center "
            >
              X
            </button>
          </div>
          <form onSubmit={handleFormSubmit} className="flex flex-col space-y-2">
            <label htmlFor="feedbackType">Feedback Type:</label>
            <select
              id="feedbackType"
              value={feedbackType}
              onChange={handleFeedbackTypeChange}
              required
            >
              <option value="">Select feedback type</option>
              <option value="Application Issue">Application Issue</option>
              <option value="Suggestion">Suggestion</option>
              <option value="Feature Request">Feature Request</option>
              <option value="Other">Other</option>
            </select>
            {error && <p className="text-red-500">{error}</p>}
            <label htmlFor="feedback">How can we improve?</label>
            <textarea
              id="feedback"
              className="p-2 h-24 w-64 border border-gray-400 rounded-md"
              type="textarea"
              value={text}
              onChange={handleFeedbackChange}
              required
            ></textarea>
            {error && <p className="text-red-500">{error}</p>}
            <button
              className="p-2 mt-2 rounded-lg bg-blue-700 hover:bg-blue-600 shadow-md hover:shadow-lg text-white"
              type="submit"
            >
              Send Feedback
            </button>
          </form>
        </div>
      )}
      {submitSuccess && (
        <div className="bg-white p-4 w-64 rounded-md shadow-lg fixed bottom-16 right-4 flex flex-col border border-green-500">
          <h1 className="text-2xl font-semibold text-green-500">
            Thank you for your feedback!
          </h1>
          <p className="mt-2 text-gray-700">
            Your feedback is valuable to us. We appreciate your time and effort.
          </p>
        </div>
      )}
    </div>
  );
}
