// import React, { useEffect, useState, Fragment } from "react";
// import { Disclosure, Menu, Transition, Dialog } from "@headlessui/react";
// import axios from "axios";
// import { Route, Routes, useNavigate } from "react-router";
// import { v4 as uuid } from "uuid";

// // import   BuyButtonComponent from components/StripeBuyButton.js
// import BuyButtonComponent from "./StripeBuyButton";

// // Import env variables
// const URL = process.env.REACT_APP_API_URL;
// // const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

// function AccountView({
//   currentMenuItem,
//   setCurrentMenuItem,
//   user,
//   setUser,
//   isAuthenticated,
//   setIsAuthenticated,
// }) {
//   const navigate = useNavigate();

//   function logout() {
//     // Send a request to the logout endpoint
//     fetch(`${URL}/logout`, {
//       method: "POST",
//       credentials: "include", // Include cookies with the request
//     })
//       .then((response) => {
//         if (response.ok) {
//           // If the response is ok, remove the user object from the state
//           setUser(null);

//           // Update the authenticated state
//           setIsAuthenticated(false);

//           // Optionally, redirect the user to the login page
//           window.location.href = "/login";
//         } else {
//           console.log("Logout failed");
//         }
//       })
//       .catch((error) => {
//         console.log(error);
//       });
//   }

//   return (
//     <div>
//       <main className="flex-1">
//         <div className="py-6">
//           <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
//             {/* <h1 className="text-2xl font-semibold text-gray-900">New Risk</h1> */}
//             <div className="py-4">
//               <div>
//                 <h2 className="text-lg leading-6 font-medium text-gray-900">
//                   Account
//                 </h2>

//                 {/* Logout Button */}
//                 <button className="ml-3" onClick={() => logout()}>
//                   Logout
//                 </button>
//               </div>
//               {/* <div className="h-96 rounded-lg border-4 border-dashed border-gray-200" /> */}
//               {/* <h2 className="text-lg leading-6 font-medium text-gray-900">
//                 Subscription
//               </h2>

//               <BuyButtonComponent /> */}
//             </div>
//           </div>
//         </div>
//       </main>
//     </div>
//   );
// }

// export default AccountView;
import React from "react";
import { useNavigate } from "react-router-dom";
import { UserCircleIcon } from "@heroicons/react/24/outline";

function AccountView({ setUser, setIsAuthenticated, user, isAuthenticated }) {
  const navigate = useNavigate();

  function logout() {
    fetch(`${process.env.REACT_APP_API_URL}/logout`, {
      method: "POST",
      // credentials: "include", // Include cookies with the request
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    })
      .then((response) => {
        if (response.ok) {
          setUser(null);
          setIsAuthenticated(false);
          localStorage.removeItem("access_token");
          navigate("/login");
        } else {
          console.log("Logout failed");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <div className="account-page bg-gray-100 p-4">
      <div className="container mx-auto">
        <div className="bg-white shadow rounded-lg p-6">
          <div className="flex items-center space-x-6 mb-4">
            <div className="flex-shrink-0">
              <UserCircleIcon
                className="h-16 w-16 text-gray-400"
                aria-hidden="true"
              />
            </div>
            <div>
              <h1 className="text-xl font-medium text-gray-900">
                {user.username}
              </h1>
              <p className="text-sm text-gray-500">{user.email}</p>
            </div>
          </div>
          <div className="border-t pt-4">
            {/* Logout Button */}
            <button
              className="py-2 px-4 bg-red-500 hover:bg-red-700 text-white font-semibold rounded-lg shadow-md"
              onClick={() => logout()}
            >
              Logout
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AccountView;
