import React, { useEffect, useState, Fragment, useMemo } from "react";
// import { Disclosure, Menu, Transition, Dialog } from "@headlessui/react";
import axios from "axios";
import { Route, Routes, useNavigate } from "react-router";
import { v4 as uuid } from "uuid";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { Transition } from "@headlessui/react";
import { CheckCircleIcon } from "@heroicons/react/24/outline";
import { XMarkIcon } from "@heroicons/react/20/solid";

// import { Button, Typography, Box, CircularProgress } from "@mui/material";
import {
  Typography,
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";

// Notification Context
import { useNotification } from "./NotificationContext";

// Import env variables
const URL = process.env.REACT_APP_API_URL;

function NewRiskView({
  currentMenuItem,
  setCurrentMenuItem,
  projectId,
  setProjectId,
  reportId,
  setReportId,
  user,
}) {
  const navigate = useNavigate();

  const [status, setStatus] = useState("");

  const [isAnalyzing, setIsAnalyzing] = useState(false);

  // notification context
  const { addNotification, removeNotification } = useNotification();

  // State Variables
  // project ID and set projectID already available
  const [template, setTemplate] = useState("Risk Analysis Template");
  const [limit, setLimit] = useState(10);
  // const [title, setTitle] = useState("Risk Analysis Report"); report title plus date
  const [title, setTitle] = useState(
    "Report: " + Date().toLocaleString().slice(0, 24)
  );
  // const [reportType, setReportType] = useState("single_keyword");
  const [reportType, setReportType] = useState("multi_keyword");
  const [promptInput, setPromptInput] = useState([]);
  const [documentType, setDocumentType] = useState("Risk Report");

  const keywords_default = [
    "GC's / GR's - Div.1 (General Conditions / General Requirements - Division 1)",
    "Allowances",
    "Areas (square footage)",
    "BR Insurance (Builder's Risk Insurance)",
    "Contingencies",
    "Documents Available",
    "DVBE (Disabled Veteran Business Enterprise)",
    "Finance Terms / Tail / Retention",
    "Liquidated Damages",
    "OCIP (Owner Controlled Insurance Program)",
    "PLA / PSA (Project Labor Agreement / Project Stabilization Agreement)",
    "Project Cost",
    "Project Description",
    "Schedule Preconstruction / Construction",
    "Schedule: Cost Loaded",
    "Security / Guards / Cameras",
    "Software (Project Management Software)",
    "Temporary Utilities",
    "Warranty Period / Duration",
  ];

  const [templates, setTemplates] = useState([]);
  // const [template, setTemplate] = useState("");

  //  Get user templates
  // Get all templates for the current user
  // const getTemplates = async () => {
  //   const response = await axios.get(`${URL}/template/user/`, {
  //     params: { user_id: user.user_id },
  //     withCredentials: true, // Include cookies with the request
  //   });

  //   const data = await response.data;
  //   console.log(data);
  //   setTemplates(data);
  // };
  const getTemplates = async () => {
    try {
      const response = await axios.get(`${URL}/template/user/`, {
        params: { user_id: user.user_id },
        // withCredentials: true, // Include cookies with the request
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });

      const data = await response.data;
      console.log(data);
      setTemplates(data);
    } catch (error) {
      console.error("Error fetching templates:", error);
      setTemplates([]);
    }
  };

  useEffect(() => {
    getTemplates();
  }, []);

  const handleTemplateChange = (event) => {
    const selectedTemplate = templates.find(
      (template) => template.template_id === event.target.value
    );
    if (selectedTemplate) {
      setTemplate(selectedTemplate.template_id);
      // set other state variables here as per the selected template
      console.log("Selected Template: ", selectedTemplate);
    } else {
      setTemplate("");
    }
  };

  //  if the "risk analysis" template is selected, then leave, else set the document type to "custom"
  useEffect(() => {
    setDocumentType("Risk Report");
    // if (template === "Risk Analysis Template") {
    //   setDocumentType("Risk Report");
    // } else {
    //   setDocumentType("Custom");
    // }
    // }, [template]);
  }, [template, setDocumentType]);

  // Get Keywords
  const [keywords, setKeywords] = useState([]);
  const [selectedKeyword, setSelectedKeyword] = useState(null);

  //  Run Report
  const runReport = async (projectId) => {
    console.log("Running report...");

    const response = await axios.post(
      `${URL}/run_report/`,
      {
        project_id: projectId,
        user_id: user.user_id,
        template: template,
        limit: limit,
        title: title,
        document_type: documentType,
        report_type: reportType,
        prompt_input: promptInput,
      },
      {
        // withCredentials: true,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      }
    );

    const data = await response.data;

    // Check if task_id is returned and start polling for status
    if (data.task_id) {
      console.log(`Report generation started. Task ID: ${data.task_id}`);
      await pollStatus(data.task_id); // You need to implement pollStatus function
    }

    return data.report_id; // Note: This might need to be handled differently based on how you update pollStatus
  };

  async function checkStatus(taskId) {
    try {
      const response = await axios.get(`${URL}/status/${taskId}`);
      if (response.data && response.data.status) {
        return response.data.status;
      }
      return "Unknown"; // Fallback status if none is provided
    } catch (error) {
      console.error(`Error checking status for task ${taskId}:`, error);
      return "Failed"; // Assume failed if there's an error
    }
  }

  async function pollStatus(taskId) {
    let status = await checkStatus(taskId);
    // Add a processing notification
    const processingNotificationId = addNotification(
      "Report generation in progress...",
      "",
      "info"
    );

    while (status === "Processing" || status === "Pending") {
      // Optionally update the notification message if needed
      await new Promise((resolve) => setTimeout(resolve, 5000));
      status = await checkStatus(taskId);
    }

    // Remove the processing notification
    removeNotification(processingNotificationId);

    // Add a new notification based on the final status
    if (status === "Completed") {
      addNotification(
        "Report Completed",
        "Your report has been successfully generated.",
        "success"
      );
    } else if (status === "Failed") {
      addNotification(
        "Report Failed",
        "Your report failed to generate.",
        "error"
      );
    }
  }

  // Run scoring report
  // const runScoringReport = async (projectId, reportId) => {
  //   const response = await axios.post(
  //     `${URL}/run_scoring_report/`,

  //     { report_id: reportId, project_id: projectId, user_id: user.user_id }, // Send projectId in the request body
  //     {
  //       withCredentials: true, // This will include the HttpOnly cookie with the request
  //     }
  //   );
  //   const data = await response.data;
  //   console.log(data);
  // };

  const [rawInput, setRawInput] = useState("");
  const [isSavedSelected, setIsSavedSelected] = useState(false); // Add a new state to track if "saved" is selected

  useEffect(() => {
    let keywords;
    if (isSavedSelected) {
      // If "saved" is selected, use the default keywords
      keywords = keywords_default;
    } else {
      // Process rawInput into an array of keywords
      keywords = rawInput.split(",").map((x) => x.trim().replace(/"/g, ""));
    }

    // Update promptInput
    setPromptInput(keywords);

    // Update reportType based on the number of keywords
    if (keywords.length > 1) {
      setReportType("multi_keyword");
    } else {
      setReportType("single_keyword");
    }
  }, [rawInput, isSavedSelected]); // This effect runs whenever rawInput or isSavedSelected changes

  //------------------------------------------------
  //  PROJECT RETRIEVAL AND DOCUMENT RETRIEVAL (FOR PROJECTS/COLLECTION MENU)
  //------------------------------------------------
  // Project Variables (Name, Documents, etc.)
  const [projectName, setProjectName] = useState("");
  const [userProjects, setUserProjects] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [projectDocs, setProjectDocs] = useState([]);
  const [filteredDocs, setFilteredDocs] = useState([]);

  //  fetch all of the user's projects
  const fetchUserProjects = async () => {
    // const response = await axios.get(`${URL}/projects/`, {
    //   params: { user_id: user.user_id },
    const response = await axios.get(`${URL}/project/user/${user.user_id}`, {
      // withCredentials: true, // This will include the HttpOnly cookie with the request
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });

    const data = await response.data;
    console.log("fetchUserProjects:", data);
    // setUserProjects(data);
    setUserProjects(data || []); // Adjusted this line
  };

  useEffect(() => {
    fetchUserProjects();
  }, []);

  // fetch all of the documents for the current user
  // const fetchDocuments = async () => {
  //   const response = await axios.get(`${URL}/documents/`, {
  //     params: { user_id: user.user_id },
  //     // withCredentials: true,
  //     headers: {
  //       Authorization: `Bearer ${localStorage.getItem("access_token")}`,
  //     },
  //   });
  //   const data = await response.data;
  //   console.log("Fetch Documents: ", data);
  //   // setDocuments(data.documents); // Adjusted this line
  //   setDocuments(data.documents || []); // Adjusted this line
  // };
  // const fetchDocuments = async () => {
  //   if (!user || !user.user_id) {
  //     console.error("User or user_id is not defined");
  //     return;
  //   }

  //   // const response = await axios.get(`${URL}/chunks/`, {
  //   const response = await axios.get(`${URL}/chunk/user/${user.user_id}`, {
  //     // params: { user_id: user.user_id },
  //     headers: {
  //       Authorization: `Bearer ${localStorage.getItem("access_token")}`,
  //     },
  //   });
  //   const chunks = response.data.chunks || []; // Provide an empty array as fallback
  //   const groupedByDocId = chunks.reduce((acc, chunk) => {
  //     acc[chunk.doc_id] = acc[chunk.doc_id] || [];
  //     acc[chunk.doc_id].push(chunk);
  //     return acc;
  //   }, {});
  //   setDocuments(groupedByDocId);
  // };
  const fetchDocuments = async () => {
    if (!user || !user.user_id) {
      console.error("User or user_id is not defined");
      return;
    }

    try {
      const response = await axios.get(`${URL}/chunk/user/${user.user_id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      const documents = response.data || [];
      const groupedByDocId = documents.reduce((acc, doc) => {
        // Ensure each document entry has a placeholder for chunks
        acc[doc.doc_id] = { ...doc, chunks: acc[doc.doc_id]?.chunks || [] };
        return acc;
      }, {});
      setDocuments(groupedByDocId);
    } catch (error) {
      console.error("Failed to fetch documents:", error);
    }
  };

  useEffect(() => {
    fetchDocuments();
  }, [user]);

  //  fetch all of the user's projects
  const fetchProjectDocs = async () => {
    // const response = await axios.get(`${URL}/project_docs/`, {
    const response = await axios.get(`${URL}/project/docs/`, {
      params: { project_id: projectId },
      // withCredentials: true, // This will include the HttpOnly cookie with the request
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });

    const data = await response.data;
    console.log("fetchProjectDocs:", data);

    // setProjectDocs(data);
    setProjectDocs(data || []); // Adjusted this line

    // Update projectName if projectId is already set
    if (projectId && data.projects) {
      const currentProject = data.projects.find(
        (project) => project.project_id === projectId
      );
      if (currentProject) {
        setProjectName(currentProject.name);
      }
    }
  };

  // const projectDocsWithNames = useMemo(() => {
  //   return projectDocs.documents
  //     ? projectDocs.documents.map((docId) => {
  //         const chunks = documents[docId]; // Access the array of chunks for this doc_id
  //         if (chunks && chunks.length > 0) {
  //           return { doc_id: docId, title: chunks[0].doc_title }; // Assuming the title is stored in each chunk
  //         }
  //         return { doc_id: docId, title: "Unknown Document" }; // Fallback title
  //       })
  //     : [];
  // }, [projectDocs, documents]);
  // const projectDocsWithNames = useMemo(() => {
  //   return projectDocs.documents
  //     ? projectDocs.documents.map(async (docId) => {
  //         try {
  //           const response = await axios.get(`${URL}/chunk/doc_id/${docId}`, {
  //             headers: {
  //               Authorization: `Bearer ${localStorage.getItem("access_token")}`,
  //             },
  //           });
  //           const chunk = response.data;

  //           return {
  //             doc_id: docId,
  //             title: chunk ? chunk.doc_title : "Unknown Document",
  //           };
  //         } catch (error) {
  //           console.error(
  //             "Failed to fetch document title for doc_id:",
  //             docId,
  //             error
  //           );
  //           return { doc_id: docId, title: "Unknown Document" };
  //         }
  //       })
  //     : [];
  // }, [projectDocs, documents]);

  const [projectDocsWithNames, setProjectDocsWithNames] = useState([]);

  useEffect(() => {
    const fetchDocuments = async () => {
      if (projectDocs.documents && projectDocs.documents.length > 0) {
        const fetchedDocs = await Promise.all(
          projectDocs.documents.map(async (docId) => {
            try {
              const response = await axios.get(`${URL}/chunk/doc_id/${docId}`, {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem(
                    "access_token"
                  )}`,
                },
              });
              const chunk = response.data;
              return {
                doc_id: docId,
                title: chunk ? chunk.doc_title : "Unknown Document",
              };
            } catch (error) {
              console.error(
                "Failed to fetch document title for doc_id:",
                docId,
                error
              );
              return { doc_id: docId, title: "Unknown Document" };
            }
          })
        );
        setProjectDocsWithNames(fetchedDocs);
      } else {
        // Clear the list if there are no documents
        setProjectDocsWithNames([]);
      }
    };

    fetchDocuments();
  }, [projectDocs, documents]); // Ensure this runs when projectDocs or documents change

  useEffect(() => {
    const filtered = Object.keys(documents)
      .filter((docId) => {
        return projectDocs.documents
          ? !projectDocs.documents.includes(docId)
          : true;
      })
      .map((docId) => documents[docId][0]); // Map to the first chunk of each filtered doc_id for display purposes

    setFilteredDocs(filtered);
  }, [documents, projectDocs, projectId]);

  const handleProjectChange = (e) => {
    const newProjectId = e.target.value;
    setProjectId(newProjectId);

    // Find the selected project and update the projectName state
    const selectedProject = userProjects.projects.find(
      (project) => project.project_id === newProjectId
    );
    if (selectedProject) {
      setProjectName(selectedProject.name);
    } else {
      setProjectName(""); // Reset the project name if no project is selected
    }
  };

  useEffect(() => {
    fetchProjectDocs();

    if (userProjects && userProjects.projects) {
      const selectedProject = userProjects.projects.find(
        (project) => project.project_id === projectId
      );
      if (selectedProject) {
        setProjectName(selectedProject.name);
      } else {
        setProjectName(""); // Reset if no project is selected
      }
    }
  }, [projectId, userProjects]);

  //------------------------------------------------
  //  KEYWORD RETRIEVAL (FOR KEYWORD MENU)
  //------------------------------------------------

  // Keyword Variables (Name, Documents, etc.)
  const [keywordName, setKeywordName] = useState("");
  const [keywordCollection, setKeywordCollection] = useState([]);
  const [keywordType, setKeywordType] = useState("");
  const [isEditing, setIsEditing] = useState(false);

  const getKeywords = async () => {
    try {
      const response = await axios.get(`${URL}/keyword/user/`, {
        params: { user_id: user.user_id },
        // withCredentials: true, // Include cookies with the request
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });

      const data = await response.data;
      console.log(data);
      setKeywords(data); // Set 'keywords' to the array of keywords
    } catch (error) {
      if (
        error.response &&
        error.response.data.detail !== "Keyword not found"
      ) {
        console.error("Error fetching keywords:", error.message);
        console.error("Error response:", error.response);
        setKeywords([]);
      }
    }
  };

  useEffect(() => {
    getKeywords();
  }, []);

  const handleKeywordChange = (event) => {
    const selectedKeyword = keywords.find(
      (keyword) => keyword.keyword_id === event.target.value
    );
    if (selectedKeyword) {
      setSelectedKeyword(selectedKeyword); // Set 'selectedKeyword' to the selected keyword
      setKeywordName(selectedKeyword.title);
      setKeywordType(selectedKeyword.keyword_type);
      setKeywordCollection(selectedKeyword.keyword_collection);

      // Update promptInput and reportType based on the selected keyword's keyword_collection
      setPromptInput(selectedKeyword.keyword_collection);
      if (selectedKeyword.keyword_collection.length > 1) {
        setReportType("multi_keyword");
      } else {
        setReportType("single_keyword");
      }
    } else {
      setSelectedKeyword(null);
      setKeywordName("");
      setKeywordType("");
      setKeywordCollection("");
      setPromptInput([]); // Reset promptInput and reportType if no keyword is selected
      setReportType("single_keyword");
    }
    setIsEditing(false); // Set 'isEditing' to false when a new keyword is selected
  };

  const handleKeywordCollectionChange = (index, event) => {
    const values = [...keywordCollection];
    values[index] = event.target.value;
    setKeywordCollection(values);
  };

  // Accordian Behavior
  const [expanded, setExpanded] = useState("panel1");

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  //  Status Message
  const [showStartedMessage, setShowStartedMessage] = useState(false);
  const [showFailedMessage, setShowFailedMessage] = useState(false);
  const [showCompletedMessage, setShowCompletedMessage] = useState(false);

  return (
    <div>
      <main className="flex-1">
        <div className="py-6">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
            <div className="py-4">
              <div>
                <h2 className="text-lg leading-6 font-medium text-gray-900">
                  Generate a Risk Analysis Report
                </h2>
              </div>
            </div>

            <div className="flex flex-col space-y-4">
              {/* <!-- Run Report Section --> */}
              {/* <div className="flex flex-col space-y-2 border-2 border-gray-900 p-4 rounded-lg"> */}
              <div>
                {/* //  start expanded */}
                <Accordion
                  // defaultExpanded
                  expanded={expanded === "panel1"}
                  onChange={handleChange("panel1")}
                  style={{ backgroundColor: "#F3F4F6" }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>1. Select Documents</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      Select the collection of documents to be analyzed.
                    </Typography>

                    {/* Radio button for "New Project" or "select Existing" */}
                    <div className="flex flex-row space-x-2 items-center py-2">
                      <div className="flex flex-row space-x-2">
                        <select
                          className="form-select border-gray-300 rounded text-sm p-2 w-96"
                          id="runReportProjectId"
                          value={projectId}
                          onChange={handleProjectChange}
                        >
                          <option value="">Select a project</option>

                          {userProjects.projects &&
                            userProjects.projects.map((project, index) => (
                              <option key={index} value={project.project_id}>
                                {project.name}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>

                    {/* List all project docs as a list */}
                    <div className="flex flex-col space-y-2">
                      <h3 className="text-md leading-6 font-medium underline text-gray-900">
                        {projectId.length > 0 ? "Documents in Collection" : ""}
                      </h3>
                      <div className="flex flex-col space-y-2">
                        {/* Display the list of documents */}
                        {/* {projectDocsWithNames.map((doc, index) => (
                          <div
                            key={index}
                            className="flex flex-row space-x-2 items-center"
                          >
                            <p className="text-sm leading-6 font-medium text-gray-900">
                            
                              {doc.title}
                            </p>
                          </div>
                        ))} */}
                        {projectDocsWithNames.map((doc, index) => (
                          <div
                            key={index}
                            className="flex flex-row space-x-2 items-center"
                          >
                            <p className="text-sm leading-6 font-medium text-gray-900">
                              {doc.title}
                            </p>
                          </div>
                        ))}
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  style={{ backgroundColor: "#F3F4F6" }}
                  expanded={expanded === "panel2"}
                  onChange={handleChange("panel2")}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                  >
                    <Typography>2. Enter Search Phrase (Keywords)</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      Enter your search phrase (keywords) to be used in the
                      document retrieval process.
                    </Typography>

                    {/* TODO:
                              -Option to create a new keyword or select existing collection of keywords? (After selection make editable?)
                              -If new, then ask for keyword name and create new keyword collection (collection?) -> Show list of all keywords to select from (or add more button?)
                              -If existing, then display and allow user to make changes as needed
                              */}

                    {/* Radio button "New" or "Saved" (dropdown of existing) */}
                    <div className="flex flex-row space-x-2">
                      <select
                        id="viewKeywords"
                        name="viewKeywords"
                        onChange={handleKeywordChange}
                        value={
                          selectedKeyword ? selectedKeyword.keyword_id : ""
                        } // use keyword_id as value
                        className="border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 h-10 sm:text-sm w-96"
                      >
                        <option value="">Select a Keyword</option>
                        {keywords.map((keyword) => (
                          <option
                            key={keyword.keyword_id}
                            value={keyword.keyword_id}
                          >
                            {keyword.title}
                          </option>
                        ))}
                      </select>
                    </div>

                    {/* View the selected keyword as a form */}
                    {/* <div className="mt-4">
                      <label
                        htmlFor="keywordName"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Keyword Name
                      </label>
                      <input
                        type="text"
                        name="keywordName"
                        id="keywordName"
                        value={keywordName}
                        onChange={(e) => setKeywordName(e.target.value)}
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        disabled={!isEditing}
                      />
                    </div> */}
                    {/* <div className="mt-4">
                      <label
                        htmlFor="keywordType"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Keyword Type
                      </label>
                      <input
                        type="text"
                        name="keywordType"
                        id="keywordType"
                        value={keywordType}
                        onChange={(e) => setKeywordType(e.target.value)}
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        disabled={!isEditing}
                      />
                    </div> */}
                    <div className="mt-4">
                      <label
                        htmlFor="keywordCollection"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Keyword Collection (Saved Search Phrases)
                      </label>

                      {/* {keywordCollection.map((keyword, index) => ( */}
                      {keywordCollection &&
                        keywordCollection.map((keyword, index) => (
                          <div key={index}>
                            {/* <input
                              type="text"
                              value={keyword}
                              onChange={(event) =>
                                handleKeywordCollectionChange(index, event)
                              }
                              disabled={!isEditing}
                              className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                            /> */}
                            {selectedKeyword &&
                              selectedKeyword.title !==
                                "Risk Analysis Keywords" && (
                                <input
                                  type="text"
                                  value={keyword}
                                  onChange={(event) =>
                                    handleKeywordCollectionChange(index, event)
                                  }
                                  disabled={!isEditing}
                                  className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                />
                              )}
                            {/* <button
                            type="button"
                            onClick={() => handleRemoveKeyword(index)}
                          >
                            Remove
                          </button> */}
                          </div>
                        ))}
                      {/* <button type="button" onClick={handleAddKeyword}>
                        Add Keyword
                      </button> */}
                    </div>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  style={{ backgroundColor: "#F3F4F6" }}
                  expanded={expanded === "panel3"}
                  onChange={handleChange("panel3")}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3a-content"
                    id="panel3a-header"
                  >
                    <Typography>3. Select Report Template</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      Select the template to be used for the report.
                    </Typography>

                    <select
                      className="border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 h-10 sm:text-sm w-96"
                      id="template"
                      onChange={handleTemplateChange}
                    >
                      <option value="">Select a template</option>
                      {templates.map((template) => (
                        <option
                          key={template.template_id}
                          value={template.template_id}
                        >
                          {template.title}
                        </option>
                      ))}
                    </select>

                    {/* Display the template text */}
                    {/* <div className="flex flex-col space-y-2">
                      <h3 className="text-md leading-6 font-medium text-gray-900 mt-4">
                        {template.length > 0 ? "Template Text" : ""}
                      </h3>
                      <div className="flex flex-col space-y-2">

                        {templates.map((templateObj) => {
                          // if (templateObj.template_id === template) { and not risk analysis template
                          if (
                            // if the template is selected and not the risk analysis template
                            templateObj.template_id === template && // if the template is selected
                            templateObj.title !== "Risk Analysis Template" // and not the risk analysis template
                          ) {
                            return (
                              <div
                                key={templateObj.template_id}
                                className="flex flex-row space-x-2 items-center"
                              >
                                <textarea
                                  className="form-input border-gray-300 rounded text-sm p-2 w-full h-96 resize-none disabled:opacity-50"
                                  id="runReportProjectId"
                                  placeholder="Enter Prompts"
                                  value={templateObj.template_text}
                                  disabled
                                />
                              </div>
                            );
                          }
                          return null;
                        })}
                      </div>
                    </div> */}
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  style={{ backgroundColor: "#F3F4F6" }}
                  expanded={expanded === "panel4"}
                  onChange={handleChange("panel4")}
                >
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>4. Enter Report Title</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      Enter the title of the report to be generated.
                    </Typography>

                    <input
                      type="text"
                      // className="form-input border-gray-300 rounded text-sm p-2"
                      className="form-input border-gray-300 rounded text-sm p-2 w-96 mt-4"
                      id="runReportProjectId"
                      placeholder="Enter Report Title"
                      value={title} //<!-- Preset value -->
                      onChange={(e) => setTitle(e.target.value)}
                    />
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  style={{ backgroundColor: "#F3F4F6" }}
                  expanded={expanded === "panel5"}
                  onChange={handleChange("panel5")}
                >
                  {/* Report Review (Submit) */}
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>5. Review and Generate</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      Review the report details and click the "Generate Report"
                      button to start the analysis.
                    </Typography>

                    <button
                      className="bg-indigo-600 hover:bg-indigo-800 text-white font-semibold py-1 px-2 rounded text-sm transition duration-300 ease-in-out self-center w-48 text-center my-4"
                      onClick={async () => {
                        setStatus("Performing search and analysis...");
                        const reportId = await runReport(projectId);
                        setReportId(reportId);
                        // close all accordions
                        setExpanded(false);
                      }}
                    >
                      Generate Report
                    </button>
                  </AccordionDetails>
                </Accordion>
              </div>
            </div>

            {isAnalyzing && (
              <div className="mb-4 bg-white shadow overflow-hidden sm:rounded-lg">
                <div className="px-4 py-5 sm:px-6">
                  <h3 className="text-lg leading-6 font-medium text-gray-900">
                    Analysis Status
                  </h3>
                  {/* <p className="mt-1 max-w-2xl text-sm text-gray-500">
                      Current status of the analysis
                    </p> */}
                </div>
                <div className="border-t border-gray-200">
                  <dl>
                    <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-medium text-gray-500">
                        {/* Status */}
                        Current Step:
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        {status}
                      </dd>
                    </div>
                    {status === "Analyzing..." && (
                      <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">
                          Progress
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                          <div className="relative pt-1">
                            <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-blue-200">
                              <div
                                style={{ width: "30%" }}
                                className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-blue-500"
                              ></div>
                            </div>
                          </div>
                        </dd>
                      </div>
                    )}
                  </dl>
                </div>
              </div>
            )}
          </div>
        </div>
      </main>

      {/*  Submitted Message */}
      <>
        {/* Global notification live region, render this permanently at the end of the document */}
        <div
          aria-live="assertive"
          className="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6"
        >
          <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
            {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
            <Transition
              // show={show}
              show={showStartedMessage}
              as={Fragment}
              enter="transform ease-out duration-300 transition"
              enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
              enterTo="translate-y-0 opacity-100 sm:translate-x-0"
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
                <div className="p-4">
                  <div className="flex items-start">
                    <div className="flex-shrink-0">
                      <CheckCircleIcon
                        className="h-6 w-6 text-green-400"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="ml-3 w-0 flex-1 pt-0.5">
                      <p className="text-sm font-medium text-gray-900">
                        Report Started!
                      </p>
                      <p className="mt-1 text-sm text-gray-500">
                        Your report is currently in progress.
                      </p>
                    </div>
                    <div className="ml-4 flex flex-shrink-0">
                      <button
                        type="button"
                        className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        onClick={() => {
                          // setShow(false)
                          setShowStartedMessage(false);
                        }}
                      >
                        <span className="sr-only">Close</span>
                        <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Transition>
          </div>
        </div>
      </>

      {/* Report Failed Message */}
      <>
        {/* Global notification live region, render this permanently at the end of the document */}
        <div
          aria-live="assertive"
          className="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6"
        >
          <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
            {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
            <Transition
              // show={show}
              show={showFailedMessage}
              as={Fragment}
              enter="transform ease-out duration-300 transition"
              enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
              enterTo="translate-y-0 opacity-100 sm:translate-x-0"
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
                <div className="p-4">
                  <div className="flex items-start">
                    <div className="flex-shrink-0">
                      {/* <CheckCircleIcon
                        className="h-6 w-6 text-green-400"
                        aria-hidden="true"
                      /> */}
                      <XMarkIcon
                        className="h-6 w-6 text-red-400"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="ml-3 w-0 flex-1 pt-0.5">
                      <p className="text-sm font-medium text-gray-900">
                        Report Failed!
                      </p>
                      <p className="mt-1 text-sm text-gray-500">
                        Your report failed to generate.
                      </p>
                    </div>
                    <div className="ml-4 flex flex-shrink-0">
                      <button
                        type="button"
                        className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        onClick={() => {
                          // setShow(false)
                          setShowFailedMessage(false);
                        }}
                      >
                        <span className="sr-only">Close</span>
                        <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Transition>
          </div>
        </div>
      </>

      {/* Report Completed Successfully */}
      <>
        {/* Global notification live region, render this permanently at the end of the document */}
        <div
          aria-live="assertive"
          className="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6"
        >
          <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
            {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
            <Transition
              // show={show}
              show={showCompletedMessage}
              as={Fragment}
              enter="transform ease-out duration-300 transition"
              enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
              enterTo="translate-y-0 opacity-100 sm:translate-x-0"
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
                <div className="p-4">
                  <div className="flex items-start">
                    <div className="flex-shrink-0">
                      <CheckCircleIcon
                        className="h-6 w-6 text-green-400"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="ml-3 w-0 flex-1 pt-0.5">
                      <p className="text-sm font-medium text-gray-900">
                        Report Completed!
                      </p>
                      <p className="mt-1 text-sm text-gray-500">
                        Your report has been generated.
                      </p>
                      {/* Button to navigate to reports tab */}
                      <button
                        className="bg-indigo-600 hover:bg-indigo-800 text-white font-semibold py-1 px-2 rounded text-sm transition duration-300 ease-in-out self-center w-48 text-center my-4"
                        onClick={() => {
                          // setShow(false)
                          setShowCompletedMessage(false);
                          navigate("/reports");
                        }}
                      >
                        View Report
                      </button>
                    </div>
                    <div className="ml-4 flex flex-shrink-0">
                      <button
                        type="button"
                        className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        onClick={() => {
                          // setShow(false)
                          setShowCompletedMessage(false);
                        }}
                      >
                        <span className="sr-only">Close</span>
                        <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Transition>
          </div>
        </div>
      </>
    </div>
  );
}

export default NewRiskView;
