// NotificationContext.js
import React, { createContext, useState, useContext } from "react";

const NotificationContext = createContext();

export const useNotification = () => useContext(NotificationContext);

export const NotificationProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([]);

  const addNotification = (title, description, type = "info") => {
    const id = Math.random().toString(36).substr(2, 9);
    setNotifications([
      ...notifications,
      { id, message: { title, description }, type },
    ]);
    return id;
  };

  const removeNotification = (id) => {
    setNotifications(
      notifications.filter((notification) => notification.id !== id)
    );
  };

  return (
    <NotificationContext.Provider
      value={{ notifications, addNotification, removeNotification }}
      // value={{ notifications, addNotification }}
    >
      {children}
    </NotificationContext.Provider>
  );
};
