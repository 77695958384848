import React, { useState, useEffect, useRef, useMemo } from "react";
import axios from "axios";
import { TextField, Paper, Typography, Button } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import { v4 as uuidv4 } from "uuid";
import { DotPulse } from "@uiball/loaders";
import Tooltip from "@mui/material/Tooltip";
import {
  Select,
  MenuItem,
  Grid,
  Box,
  InputLabel,
  FormControl,
} from "@mui/material";
import Collapse from "@mui/material/Collapse";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore.js";
import IconButton from "@mui/material/IconButton";

// Styling for messages?

import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

import { styled } from "@mui/material/styles";
import { tooltipClasses } from "@mui/material/Tooltip";

// Import env variables
const URL = process.env.REACT_APP_API_URL;

const GlobalChat = ({
  currentReport,
  selectedKey,
  selectedCategory,
  requestType,
  reportContent,
  user,
  projectId,
  setProjectId,
}) => {
  const [messages, setMessages] = useState([
    {
      type: "assistant",
      content: { message: "Hello! How can I help you today?" },
    },
  ]);
  const [inputValue, setInputValue] = useState("");
  const [systemMessage, setSystemMessage] = useState("");
  const [chatId, setChatId] = useState(uuidv4());
  const [isLoading, setIsLoading] = useState(false);
  const [shadow, setShadow] = useState(false);
  const [openChat, setOpenChat] = useState(false);
  const [open, setOpen] = useState(false);
  const [assistantMessage, setAssistantMessage] = useState("");
  const [userId, setUserId] = useState(user ? user.user_id : "");
  // project state variables
  const [selectedProjectId, setSelectedProjectId] = useState(""); // State for selected project ID

  // Add a ref to the ChatContainer
  const chatContainerRef = useRef(null);

  useEffect(() => {
    const chatContainer = chatContainerRef.current;
    if (chatContainer) {
      const observer = new MutationObserver(() => {
        const { scrollHeight } = chatContainer;
        chatContainer.scrollTo(0, scrollHeight);
      });

      observer.observe(chatContainer, { childList: true });

      return () => observer.disconnect();
    }
  }, []);

  useEffect(() => {
    if (!isLoading && messages[messages.length - 1]?.type === "assistant") {
      setShadow(true);
      const timer = setTimeout(() => {
        setShadow(false);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [isLoading, messages]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const trimmedInput = inputValue.trim(); // Store trimmed input value
    setInputValue(""); // Clear input immediately after submission
    setSearchResults([]); // Clear previous search results

    if (trimmedInput) {
      setIsLoading(true);
      setMessages([...messages, { type: "user", content: trimmedInput }]);

      // Perform immediate search
      const searchResults = await performSearch(trimmedInput);

      // // Process the chat with the search results
      try {
        // After obtaining search results

        const chatResponse = await axios.post(
          `${URL}/chat/`,
          {
            user_message: inputValue,
            system_message:
              requestType === "report" ? JSON.stringify(reportContent) : "",
            request_type: requestType || "global",
            chat_id: chatId,
            project_id: selectedProjectId,
            search_results: searchResults, // This should be an array of chunk data including metadata
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
          }
        );

        setMessages((prevMessages) => [
          ...prevMessages,
          {
            type: "assistant",
            content: {
              message: chatResponse.data.assistant_message,
              sources: Array.isArray(chatResponse.data.metadata)
                ? chatResponse.data.metadata.map((chunk) => ({
                    title: chunk.doc_title || "No Title Provided",
                    page_number: chunk.metadata?.page_number || "Not Available",
                    content: chunk.text || "No Text Available",
                  }))
                : [], // If metadata is not an array, set sources to an empty array
            },
          },
        ]);
      } catch (err) {
        console.error("Error communicating with the backend:", err);
        setMessages((prevMessages) => [
          ...prevMessages,
          {
            type: "assistant",
            content: "Sorry, I encountered an error. Please try again later.",
          },
        ]);
      } finally {
        setIsLoading(false);
        setInputValue("");
      }
    }
  };

  // set the chunk content state variable
  const [chunkContent, setChunkContent] = useState("");

  useEffect(() => {
    console.log("useEffect for chunk content called");
    console.log("chunkContent:", chunkContent);
  }, [chunkContent]);

  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#333333",
      color: "#fff",
      maxWidth: 500,
      fontSize: theme.typography.pxToRem(12),
      // border: "1px solid #dadde9",
    },
  }));

  const [currentMessageKey, setCurrentMessageKey] = useState("");

  const handleChatInputChange = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSubmit(e);
    } else {
      setInputValue(e.target.value);
      e.target.style.height = "inherit";
      e.target.style.height = `${e.target.scrollHeight}px`;
    }
  };

  const textAreaRef = useRef(null);
  const [projectName, setProjectName] = useState("");
  const [userProjects, setUserProjects] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [projectDocs, setProjectDocs] = useState([]);
  const [filteredDocs, setFilteredDocs] = useState([]);

  // const fetchUserProjects = async () => {
  //   if (!user || !user.user_id) {
  //     console.error("User or user_id is not defined");
  //     return;
  //   }

  //   const response = await axios.get(`${URL}/project/user/${user.user_id}`, {
  //     headers: {
  //       Authorization: `Bearer ${localStorage.getItem("access_token")}`,
  //     },
  //   });

  //   const data = await response.data;
  //   console.log("fetchUserProjects:", data);

  //   setUserProjects(data.projects || []); // Set an empty array as a fallback
  // };
  // const fetchUserProjects = async () => {
  //   if (!user || !user.user_id) {
  //     console.error("User or user_id is not defined");
  //     return;
  //   }

  //   try {
  //     const response = await axios.get(`${URL}/project/user/${user.user_id}`, {
  //       headers: {
  //         Authorization: `Bearer ${localStorage.getItem("access_token")}`,
  //       },
  //     });

  //     if (response.status === 200) {
  //       const data = response.data;
  //       console.log("fetchUserProjects:", data);
  //       setUserProjects(data.projects || []); // Set an empty array as a fallback
  //     } else {
  //       console.error(
  //         `Failed to fetch projects, status code: ${response.status}`
  //       );
  //       setUserProjects([]); // Set to empty array if the request fails
  //     }
  //   } catch (error) {
  //     console.error("Error fetching user projects:", error);
  //     setUserProjects([]); // Set to empty array on network error or server error
  //   }
  // };
  const fetchUserProjects = async () => {
    if (!user || !user.user_id) {
      console.error("User or user_id is not defined");
      return;
    }

    try {
      const response = await axios.get(`${URL}/project/user/${user.user_id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });

      if (response.status === 200 && response.data.projects.length > 0) {
        console.log("fetchUserProjects:", response.data);
        setUserProjects(response.data.projects);
      } else {
        console.log(response.data.message); // Log the message from the server
        setUserProjects([]); // Set to empty array if no projects are found
      }
    } catch (error) {
      console.error("Error fetching user projects:", error);
      setUserProjects([]); // Set to empty array on network error or server error
    }
  };

  useEffect(() => {
    if (user) {
      fetchUserProjects();
    }
  }, [user]);

  // Fetch documents for the user
  // const fetchDocuments = async () => {
  //   if (!user || !user.user_id) {
  //     console.error("User or user_id is not defined");
  //     return;
  //   }

  //   // const response = await axios.get(`${URL}/chunks/`, {
  //   const response = await axios.get(`${URL}/chunk/user/${user.user_id}`, {
  //     headers: {
  //       Authorization: `Bearer ${localStorage.getItem("access_token")}`,
  //     },
  //   });
  //   const chunks = response.data.chunks || []; // Provide an empty array as fallback
  //   const groupedByDocId = chunks.reduce((acc, chunk) => {
  //     acc[chunk.doc_id] = acc[chunk.doc_id] || [];
  //     acc[chunk.doc_id].push(chunk);
  //     return acc;
  //   }, {});
  //   setDocuments(groupedByDocId);
  // };
  const fetchDocuments = async () => {
    if (!user || !user.user_id) {
      console.error("User or user_id is not defined");
      return;
    }

    try {
      const response = await axios.get(`${URL}/chunk/user/${user.user_id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      const documents = response.data || [];
      const groupedByDocId = documents.reduce((acc, doc) => {
        // Ensure each document entry has a placeholder for chunks
        acc[doc.doc_id] = { ...doc, chunks: acc[doc.doc_id]?.chunks || [] };
        return acc;
      }, {});
      setDocuments(groupedByDocId);
    } catch (error) {
      console.error("Failed to fetch documents:", error);
    }
  };

  // useEffect(() => {
  //   fetchDocuments();
  // }, [user]);
  useEffect(() => {
    if (user && user.user_id) {
      fetchDocuments();
    }
  }, [user]); // This useEffect will now only trigger when `user` changes and is defined.

  //  fetch all of the user's projects
  const fetchProjectDocs = async () => {
    // const response = await axios.get(`${URL}/project_docs/`, {
    const response = await axios.get(`${URL}/project/docs/`, {
      params: { project_id: projectId },
      // withCredentials: true, // This will include the HttpOnly cookie with the request
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });

    // print accessToken
    console.log("access_token:", localStorage.getItem("access_token"));

    const data = await response.data;
    console.log("fetchProjectDocs:", data);

    setProjectDocs(data);

    // Update projectName if projectId is already set
    if (projectId && data.projects) {
      const currentProject = data.projects.find(
        (project) => project.project_id === projectId
      );
      if (currentProject) {
        setProjectName(currentProject.name);
      }
    }
  };

  useEffect(() => {
    const filtered = Object.keys(documents)
      .filter((docId) => {
        return projectDocs.documents
          ? !projectDocs.documents.includes(docId)
          : true;
      })
      .map((docId) => documents[docId][0]); // Map to the first chunk of each filtered doc_id for display purposes

    setFilteredDocs(filtered);
  }, [documents, projectDocs, projectId]);

  const handleProjectChange = (e) => {
    const newProjectId = e.target.value;
    setProjectId(newProjectId);

    // Find the selected project and update the projectName state
    const selectedProject = userProjects.projects.find(
      (project) => project.project_id === newProjectId
    );
    if (selectedProject) {
      setProjectName(selectedProject.name);
    } else {
      setProjectName(""); // Reset the project name if no project is selected
    }
  };

  useEffect(() => {
    fetchProjectDocs();

    if (userProjects && userProjects.projects) {
      const selectedProject = userProjects.projects.find(
        (project) => project.project_id === projectId
      );
      if (selectedProject) {
        setProjectName(selectedProject.name);
      } else {
        setProjectName(""); // Reset if no project is selected
      }
    }
  }, [projectId, userProjects]);

  const [openSide, setOpenSide] = useState(true);

  const performSearch = async (userInput) => {
    // Ensure that user and user.user_id are available
    if (!user || !user.user_id) {
      console.error("User ID is not available for the search request.");
      return [];
    }

    // Log the request payload for debugging
    console.log("Search request payload:", {
      prompt: userInput,
      project_id: selectedProjectId,
      user_id: user.user_id,
    });

    try {
      const searchResponse = await axios.post(
        `${URL}/search_index`,
        {
          prompt: userInput,
          project_id: selectedProjectId,
          user_id: user.user_id,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      );

      setSearchResults(searchResponse.data.metadata);
      return searchResponse.data.metadata;
    } catch (err) {
      console.error("Error performing index search:", err);
      setSearchResults([]);
      return [];
    }
  };

  const [searchResults, setSearchResults] = useState([]);

  return (
    <div className="flex flex-row h-full">
      <div
        className={`flex flex-col justify-end ${
          openSide ? "w-2/3" : "w-full"
        } h-full p-2`}
      >
        {/* <div className="flex flex-col justify-end h-full p-2 overflow-auto"> */}
        <div className="overflow-auto py-4 pt-20" ref={chatContainerRef}>
          {/* Adjust paddingTop as necessary */}
          {messages.map((message, index) => (
            <div key={index} className="mb-1">
              <p className="font-bold text-white">
                {message.type === "user" ? "You" : "Assistant"}
              </p>
              {message.type === "user" ? (
                <div
                  className={`w-full inline-block p-4 rounded-lg border border-gray-500 bg-gray-200 ${
                    index === messages.length - 1 && shadow
                      ? "shadow shadow-blue-300"
                      : ""
                  }`}
                >
                  {message.content}
                </div>
              ) : (
                <div
                  className={`w-full inline-block p-4 rounded-lg border border-gray-500 ${
                    index === messages.length - 1 && shadow
                      ? "shadow shadow-blue-300"
                      : ""
                  }`}
                >
                  <div>
                    <div
                      className={`text-md ${
                        requestType === "report"
                          ? "text-white"
                          : "text-gray-600"
                      }`}
                    >
                      <ReactMarkdown remarkPlugins={[remarkGfm]}>
                        {message.content.message}
                      </ReactMarkdown>
                    </div>

                    {Array.isArray(message.content.sources) &&
                      message.content.sources.length > 0 && (
                        <div className="flex flex-row items-center text-xs font-small text-gray-700">
                          <IconButton
                            onClick={() => {
                              setCurrentMessageKey(index);
                              setOpen((prev) => !prev);
                            }}
                            aria-expanded={open && currentMessageKey === index}
                            aria-label="show more"
                            // size="small"
                            className="p-0 m-0 w-6 h-6"
                          >
                            <ExpandMoreIcon
                              className={`transform duration-500 ${
                                open && currentMessageKey === index
                              }`}
                            />
                          </IconButton>

                          <div className="flex flex-row items-center space-x-2">
                            {/* <div className="text-xs font-semibold text-gray-600"> */}
                            {/* make assistant text gray unless requestType is report */}
                            <div
                              className={`text-xs font-semibold ${
                                requestType === "report"
                                  ? "text-white"
                                  : "text-gray-600"
                              }`}
                            >
                              {message.content.sources.length}
                            </div>

                            <span className="text-xs font-medium text-gray-600">
                              Reference(s)
                            </span>
                          </div>
                        </div>
                      )}

                    <div className="mt-2">
                      {/* <Collapse in={open}> */}
                      <Collapse in={open && currentMessageKey === index}>
                        <div className="flex flex-col gap-1">
                          {Array.isArray(message.content.sources) &&
                            message.content.sources.map(
                              (source, chunk_index) => (
                                <div className="border border-gray-400 rounded-md p-2 mb-1">
                                  <HtmlTooltip
                                    arrow
                                    key={chunk_index}
                                    title={
                                      <React.Fragment>
                                        <Typography
                                          color="inherit"
                                          sx={{
                                            p: 1,
                                            fontSize: "1.2em",
                                            fontWeight: "bold",
                                          }}
                                        >
                                          <span
                                            style={{
                                              display: "block",
                                              width: "450px", // adjust this value as needed
                                              overflow: "hidden",
                                              whiteSpace: "nowrap",
                                              textOverflow: "ellipsis",
                                            }}
                                          >
                                            {source.title}
                                          </span>
                                        </Typography>
                                        <em style={{ fontSize: "1.2em" }}>
                                          {source.content}
                                        </em>
                                        <br />
                                        <em
                                          style={{
                                            fontSize: "1em",
                                            color: "lightgray",
                                          }}
                                        >
                                          Chunk Index: {source.chunk_index}
                                        </em>
                                      </React.Fragment>
                                    }
                                  >
                                    <div
                                      className="flex flex-row gap-1 items-start text-xs font-semibold text-blue-600"
                                      style={{
                                        wordWrap: "break-word",
                                        whiteSpace: "nowrap",
                                        overflowX: "auto",
                                      }}
                                    >
                                      <div className="border border-radius-2 border-gray-400 rounded-md w-5 h-5 items-center text-center text-gray-600">
                                        <p>{chunk_index + 1}</p>
                                      </div>
                                      <div>{source.title}</div>
                                    </div>
                                  </HtmlTooltip>
                                </div>
                              )
                            )}
                        </div>
                      </Collapse>
                    </div>
                  </div>
                </div>
              )}
            </div>
          ))}
          {isLoading && (
            <div>
              <p className="font-bold text-white">Assistant</p>
              <div className="w-full p-4 rounded-lg flex items-center shadow shadow-blue-300 bg-gray-200">
                {/* <div className="w-full p-4 rounded-lg flex items-center shadow shadow-blue-300 bg-gray-800 text-gray-300"> */}
                <p className="mr-2">Thinking</p>
                <DotPulse size={30} speed={1.3} color="black" />
              </div>
            </div>
          )}
        </div>

        <form className="flex w-full items-center pb-4" onSubmit={handleSubmit}>
          <div
            className="overflow-hidden flex flex-col w-full flex-grow relative border border-token-border-heavy  bg-gray-200  border-gray-500 shadow-xs rounded-sm"
            style={{ maxHeight: "200px" }}
          >
            <textarea
              ref={textAreaRef}
              className="m-0 w-full resize-none border-0 bg-transparent py-[10px] pr-10 focus:ring-0 focus-visible:ring-0 dark:bg-transparent md:py-3.5 "
              placeholder="Type your message..."
              value={inputValue}
              onChange={handleChatInputChange}
              onKeyDown={handleChatInputChange}
              rows={1} // minimum number of rows
              style={{ overflowY: "auto" }} // changed from "hidden" to "auto"
            />
            <button
              type="submit"
              className="absolute md:bottom-1 md:right-3 dark:hover:bg-gray-900 dark:disabled:hover:bg-transparent right-2 dark:disabled:bg-white disabled:bg-black disabled:opacity-10 disabled:text-gray-400 enabled:bg-black text-white p-0.5 border border-black rounded-lg dark:border-white dark:bg-white bottom-1.5 transition-colors"
            >
              <SendIcon
                style={{
                  color: "white",
                  marginTop: 8,
                  marginBottom: 8,
                  marginRight: 4,
                  marginLeft: 4,
                  padding: 1,
                }}
              />
            </button>
          </div>
        </form>
      </div>

      {openSide && (
        <aside className="w-1/3 h-screen flex flex-col bg-gray-800 px-2">
          <div className="flex flex-col h-full">
            <Grid item xs={12} className="flex flex-col h-full">
              <div className="mt-4 flex flex-col h-full py-2">
                {Array.isArray(userProjects) && (
                  <div className="flex flex-col items-start px-4 md:px-0">
                    <h2 className="text-lg leading-6 font-medium text-white mb-2">
                      Select Project:
                    </h2>

                    <div className="w-full mb-2">
                      <select
                        value={selectedProjectId}
                        onChange={(e) => setSelectedProjectId(e.target.value)}
                        className="form-select appearance-none block w-full px-3 py-2 text-base font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                      >
                        <option value="">RFPs</option>
                        {userProjects.map((project) => (
                          <option
                            key={project.project_id}
                            value={project.project_id}
                          >
                            {project.name} Project
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                )}
                <h2 className="text-lg leading-6 font-medium text-white mb-2">
                  Source Text:
                </h2>

                <div className="overflow-auto flex-grow text-white">
                  {searchResults.map((result, index) => (
                    <div
                      key={index}
                      className="mb-2 p-4 bg-gray-700 rounded-lg shadow-md"
                    >
                      {/* <p className="font-bold pb-2 underline">{result.title}</p> */}
                      <p className="font-bold pb-2 underline">
                        {result.doc_title}
                      </p>
                      <p>{result.text}</p>
                      <p className="text-sm text-gray-400">
                        {/* Chunk Index: {result.chunk_index} */}
                        Page Number: {result.metadata?.page_number}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            </Grid>
          </div>
        </aside>
      )}
    </div>
  );
};

export default GlobalChat;
