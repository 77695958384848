import React, { useEffect, useState, Fragment, useRef } from "react";
import axios from "axios";
import { Route, Routes, useNavigate } from "react-router";
import { v4 as uuid } from "uuid";
import Chatbot from "./Chatbot.js";
import GlobalChat from "./GlobalChat.js";

import {
  Select,
  MenuItem,
  Grid,
  Paper,
  Box,
  Typography,
  InputLabel,
  FormControl,
} from "@mui/material";

// import { Tabs, Tab } from "@material-ui/core";
import { Tabs, Tab, Button } from "@mui/material";

import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import supersub from "remark-supersub";

import { styled } from "@mui/material/styles";
import { tooltipClasses } from "@mui/material/Tooltip";
import Tooltip from "@mui/material/Tooltip";

// Import env variables
const URL = process.env.REACT_APP_API_URL;

function ReportsView({
  currentMenuItem,
  setCurrentMenuItem,
  projectId,
  setProjectId,
  reportId,
  setReportId,
  user,
}) {
  const navigate = useNavigate();
  const [reports, setReports] = useState([]);
  const [selectedValue, setSelectedValue] = useState("");
  // const [userId, setUserId] = useState(user.user_id || "");
  const [userId, setUserId] = useState(user?.user_id || "");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedKey, setSelectedKey] = useState("");
  // const [selectedCategoryIndex, setSelectedCategoryIndex] = useState(0);
  const [selectedCategoryIndex, setSelectedCategoryIndex] = useState();
  const [open, setOpen] = useState(false);
  const [currentCitation, setCurrentCitation] = useState("");
  const [openedChat, setOpenedChat] = useState({ listId: null, index: null });
  const [reportContent, setReportContent] = useState({});

  const [projects, setProjects] = useState([]);
  const [selectedProjectId, setSelectedProjectId] = useState("");
  // const [selectedItemIndex, setSelectedItemIndex] = useState(0);
  const [selectedItemIndex, setSelectedItemIndex] = useState();

  const fetchProjects = async () => {
    try {
      // const response = await axios.get(`${URL}/projects/`, {
      // params: { user_id: user.user_id },
      // withCredentials: true,
      const response = await axios.get(`${URL}/project/user/${user.user_id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      const fetchedProjects = response.data.projects || []; // Adjust according to your actual API response
      setProjects(fetchedProjects);

      if (fetchedProjects.length > 0) {
        const lastProject = fetchedProjects[fetchedProjects.length - 1];
        setSelectedProjectId(lastProject.project_id);
        // Since setting state is asynchronous, use a callback to ensure this runs after the state is updated
        setProjects(fetchedProjects, () => {
          // Automatically select the last project in the list if there are any projects
          setSelectedProjectId(lastProject.project_id);

          // Default select the first category and item
          const firstCategoryKey = Object.keys(CATEGORIES)[0];
          if (firstCategoryKey) {
            setSelectedCategoryIndex(0);
            setSelectedCategory(firstCategoryKey);
            setSelectedItemIndex(0);
          }
        });
      }
    } catch (error) {
      console.error("Error fetching projects:", error);
    }
  };

  // Fetch Reports
  const fetchReports = async () => {
    try {
      const response = await axios.get(
        `${URL}/reports/?user_id=${user.user_id}`,
        // { withCredentials: true }
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      );
      setReports(response.data.reports || []);
      console.log("Fetched reports:", response.data.reports);
    } catch (error) {
      console.error("Failed to fetch reports:", error);
    }
  };

  useEffect(() => {
    fetchProjects();
    fetchReports();
  }, []); // Empty dependency array means this effect runs once on mount

  useEffect(() => {
    if (projects.length > 0) {
      const lastProject = projects[projects.length - 1];
      setSelectedProjectId(lastProject.project_id);
    }
  }, [projects]); // This effect runs when `projects` changes

  useEffect(() => {
    if (selectedProjectId && CATEGORIES) {
      const firstCategoryKey = Object.keys(CATEGORIES)[0];
      if (firstCategoryKey) {
        setSelectedCategoryIndex(0);
        setSelectedCategory(firstCategoryKey);
        setSelectedItemIndex(0);
      }
    }
  }, [selectedProjectId]); // This effect runs when `selectedProjectId` changes

  // Handle project selection change
  const handleProjectSelectionChange = (event) => {
    setSelectedProjectId(event.target.value);
    console.log("Selected project:", event.target.value);
  };

  // // Filter reports based on selected project
  // const filteredReports = reports.filter(
  //   (report) => report.project_id === selectedProjectId
  // );
  const [filteredReports, setFilteredReports] = useState([]);

  useEffect(() => {
    // Filter reports based on the currently selected project ID
    const updatedFilteredReports = reports.filter(
      (report) => report.project_id === selectedProjectId
    );
    setFilteredReports(updatedFilteredReports);
    console.log("Filtered reports:", updatedFilteredReports);
  }, [reports, selectedProjectId]); // Re-run this effect when `reports` or `selectedProjectId` changes

  // ---------------------------
  // Determine if the report is default or custom (use categories or dynamically map through keys)
  // ---------------------------

  const CATEGORIES = {
    "Project Basics": [
      "Documents Available",
      "Project Cost",
      "Project Description",
      "Areas (square footage)",
    ],
    "Finance & Insurance": [
      "Allowances",
      "BR Insurance (Builder's Risk Insurance)",
      "Contingencies",
      "Finance Terms / Tail / Retention",
      "OCIP (Owner Controlled Insurance Program)",
      "Liquidated Damages",
    ],
    "Schedule & Milestones": [
      "Schedule Preconstruction / Construction",
      "Schedule: Cost Loaded",
      "Warranty Period / Duration",
    ],
    "Scope & Resources": [
      "GC's / GR's - Div.1 (General Conditions / General Requirements - Division 1)",
      "Software (Project Management Software)",
      "Temporary Utilities",
      "Security / Guards / Cameras",
    ],
    // "Dispute & Resolutions": [],
    "Labor & Staffing": [
      "PLA / PSA (Project Labor Agreement / Project Stabilization Agreement)",
      "Disadvantaged Business Enterprise (DBE)",
    ],
    // "Additional Provisions": [],
  };

  // Question Mapping
  const QUESTIONS = {
    "Documents Available": [
      {
        "What documents are included within the Request for Proposal (RFP)?":
          "Provide list of documents in the RFP. Include titles of Exhibits and attachments.",
      },
      {
        "Are Plans available?":
          "Does the RFP include project designs? If these do not exist, confirm by stating 'No Plans were found within the RFP package",
      },
      {
        "Are Specifications Available?":
          "Does the RFP provide any Specifications? If not, indicate no. If yes, provide a numbered list of the Specifications and a brief description of each specification only if there is available context.",
      },
      {
        "Is the Contract Available?": "Does the RFP contain a contract?",
      },
      {
        "Is there a Project Schedule?":
          "Is there a project schedule indicated in the RFP by the school district?",
      },
      {
        "Are there any As-built Plans?": "Does the RFP contain As-built Plans?",
      },
      {
        "Is there a Geotechnical Report?":
          "Does the RFP contain a geotechnical report?",
      },
      {
        "Is there a Hazardous Material Report?":
          "Does the RFP contain a Hazardous Materials Report?",
      },
      {
        "Are there any Criteria Documents?":
          "Are there any Criteria Documents associated with Design-Build delivery",
      },
      {
        "Is there an existing Topo Plan?":
          "Does the RFP contain an existing Topo Plan or Topographical Plan?",
      },
      {
        "Is there an existing Utility Site Plan?":
          "Does the RFP contain an existing Utility Site Plan?",
      },
    ],

    "Project Cost": [
      {
        "What is The budget for this project?":
          "Does the RFP reference a project cost or budget?",
      },
      {
        "Is there an Engineer’s Estimate?":
          "Does the RFP reference or include an Engineer's Estimate?",
      },
      {
        "Are there any alternate prices?":
          "Does the RFP reference or include Alternate Prices?",
      },
    ],
    "Project Description": [
      {
        "What is the project delivery type, i.e. Design-build, Lease-leaseback, CMAR, etc.?:":
          "Review the RFP package. Look for any segments that mention 'project delivery', 'delivery method', 'Design-build', 'Lease-leaseback', 'CMAR', or other similar phrases. List down the project delivery type, if mentioned specifically. If not mentioned, return 'Project delivery type not indicated.'",
      },
      {
        "Is this new or existing construction?":
          "Is the project new construction or renovation of existing buildings or both?",
      },
      {
        "How many buildings?":
          "What buildings are part of the project? List names if available.",
      },
      {
        "What is the site area?": "Is there a site square footage indicated?",
      },
      {
        "What type of structure?":
          "What type of building structures are part of the project?",
      },
      {
        "What elements are unique to this project?":
          "Closely examine the RFP package. Search for any distinctive features which may not be standard in similar projects. Any special requirements, unique arrangements, exclusive skills, or divergent tasks can be classified as unique elements. List these unique elements alongside their corresponding descriptions. If there are no unique elements, confirm by stating 'No unique elements found in the RFP package'.",
      },
      {
        "Are there any design components?":
          "Is the contractor responsible for any design aspects in this project?",
      },
      {
        "Are there any subcontractor listing requirements?":
          'Are there any subcontractor listing requirements?  If no such mentions occur, return, "No subcontractor listing requirements detected within the RFP."',
      },
      {
        "What are the requirements for preconstruction services?":
          "Are requirements for preconstruction services indicated in the RFP?",
      },
      {
        "What are the requirements for construction services?":
          "What are the requirements for construction services? If no such mentions occur, return, 'No requirements for construction services detected within the RFP.'",
      },
      {
        "What are the requirements for design services?":
          "What are the requirements for design services? If no such mentions occur, return, 'No requirements for design services detected within the RFP.'",
      },
      {
        "Does the RFP require Building Information Modeling BIM services?":
          "Does the RFP require Building Information Modeling BIM services?",
      },
      {
        "Does the RFP indicate any requirements for early procurement of materials and equipment?":
          "What are the requirements for early procurement of materials and equipment? If no such mentions occur, return, 'No requirements for early procurement of materials and equipment detected within the RFP.'",
      },
    ],
    "Areas (square footage)": [
      {
        "Does the RFP indicate the overall square footage of the site area?":
          "Does the RFP indicate the overall square footage of the site area?",
      },
      {
        "What is square footage of buildings?":
          "What is square footage of buildings?",
      },
      {
        "What is the overall site area?":
          "Examine the RFP package carefully. Look for any mentions or specifications about the 'Overall Site Area' or equivalent references. If such information exists, deliver a concise summary including the value and the units of measure (for example, acres or square feet). If these references are not identified, return 'No specific details about Overall Site Area found within the RFP.'",
      },
    ],
    Allowances: [
      {
        "What allowances are included in this project?":
          "What allowances are included in this project? If not indicated, return, Allowance provisions not indicated.",
      },
      {
        "How are allowances defined in the contract documents?":
          "How are allowances defined in the contract documents?",
      },
      {
        "How are allowances used?": "How are allowances used?",
      },
      {
        "Who controls the allowances?": "Who controls the allowances?",
      },
    ],
    Contingencies: [
      {
        "What contingencies are included in this project?":
          "What contingencies are included in this project?",
      },
      {
        "How are contingencies defined in the contract documents?":
          "How are contingencies defined in the contract documents?",
      },
      {
        "How are contingencies used?": "How are contingencies used?",
      },
      {
        "Who controls the contingencies?": "Who controls the contingencies?",
      },
      {
        "Is there a shared savings of unused contingencies?":
          "Is there a shared savings of unused contingencies?",
      },
    ],
    "Finance Terms / Tail / Retention": [
      {
        "Is there a financing component to this project?":
          "Is there a financing component to this project?",
      },
      {
        "How long is the financing period?":
          "What is the duration of the lease payments from start to completion If not indicated, return, No finance period indicated.",
      },
      {
        "Is a financing fee required?": "Is a financing fee required?",
      },
      {
        "Has a financing fee already been designated?":
          "Has a financing fee already been designated?",
      },

      {
        "What percentage of the project is being financed?":
          "What percentage of the project is being financed?",
      },
      {
        "What is the percentage for retention?":
          "Is there a percentage for retention indicated?",
      },
      {
        "What permits and fees are required by authorities having jurisdiction and utilities?":
          "Are any permits and fees required by authorities having jurisdiction over utilities?",
      },
      {
        "Who is paying for permitting fees and utility fees?":
          "Who is paying for permitting fees and utility fees?",
      },
      {
        "When is the Guaranteed Maximum Price (GMP) established?":
          "When is the Guaranteed Maximum Price (GMP) established?",
      },
    ],
    "BR Insurance (Builder's Risk Insurance)": [
      {
        "What are the insurance requirements for this project?":
          "What are the insurance requirements for this project?",
      },
      {
        "What are the Builder’s Risk Insurance Requirements for this Project?":
          "What are the Builder’s Risk Insurance Requirements for this Project?",
      },
      // {
      //   "Does this project have an Owner Controlled Insurance Program (OCIP)?":
      //     "Does this project have an Owner Controlled Insurance Program (OCIP)?",
      // },
      // {
      //   "Have the OCIP requirements been provided?":
      //     "Have the OCIP requirements been provided?",
      // },
    ],
    "OCIP (Owner Controlled Insurance Program)": [
      {
        "Does this project have an Owner Controlled Insurance Program (OCIP)?":
          "Does this project have an Owner Controlled Insurance Program (OCIP)?",
      },
      {
        "Have the OCIP requirements been provided?":
          "Have the OCIP requirements been provided?",
      },
    ],
    "Schedule Preconstruction / Construction": [
      {
        "What are the key dates for this project?":
          "What are the key dates for this project?",
      },
      {
        "When does pre-construction begin?":
          "When does pre-construction begin?",
      },
      {
        "When do the construction documents get submitted for permitting?":
          "When do the construction documents get submitted for permitting?",
      },
      {
        "What is the estimated start date for construction?":
          "What is the estimated start date for construction?",
      },
      {
        "What is the estimated completion date for construction?":
          "What is the estimated completion date for construction?",
      },
      {
        "Are there any phasing requirements?":
          "Are there any phasing requirements?",
      },
      // {
      //   "Are there any liquidated damages?":
      //     "Are there any liquidated damages?",
      // },
      // {
      //   "What is the amount of liquidated damages?":
      //     "What is the amount of liquidated damages?",
      // },
      {
        "Are there any provisions for compensable delays?":
          "Are there any provisions for compensable delays?",
      },
    ],
    "Liquidated Damages": [
      {
        "Are there any liquidated damages?":
          "Are there any liquidated damages?",
      },
      {
        "What is the amount of liquidated damages?":
          "What is the amount of liquidated damages?",
      },
    ],
    "Schedule: Cost Loaded": [
      {
        "Does this project require cost loading of the project schedule?":
          "Does this project require cost loading of the project schedule?",
      },
    ],
    "Warranty Period / Duration": [
      {
        "What is the duration of the warranty period?":
          "What is the duration of the warranty period?",
      },
      {
        "When does the warranty period begin?":
          "When does the warranty period begin?",
      },
    ],
    "GC's / GR's - Div.1 (General Conditions / General Requirements - Division 1)":
      [
        {
          "Are General Conditions provided?":
            "Are General Conditions provided?",
        },
        {
          "Is there a Responsibility Matrix provided for General Conditions?":
            "Is there a Responsibility Matrix provided for General Conditions?",
        },
        {
          "Are the General Requirements defined in this project?":
            "Are the General Requirements defined in this project?",
        },
        {
          "Is an office required for the Owner, Architect, or Inspector?":
            "Is an office required for the Owner, Architect, or Inspector?",
        },
      ],
    "Software (Project Management Software)": [
      {
        "Is there a requirement for project management and document control software?":
          "Is there a requirement for project management and document control software?",
      },
    ],
    "Temporary Utilities": [
      {
        "Who is responsible for paying monthly utility fees during construction?":
          "Who is responsible for paying monthly utility fees during construction?",
      },
      {
        "Are there existing power and water utilities on site?":
          "Are there existing power and water utilities on site?",
      },
    ],
    "Security / Guards / Cameras": [
      {
        "Are security guards required for this project?":
          "Are security guards required for this project?",
        "Are security cameras required for this project?":
          "Are security cameras required for this project?",
      },
    ],
    "PLA / PSA (Project Labor Agreement / Project Stabilization Agreement)": [
      {
        "Does the project contain a Project Labor Agreement (PLA) or Project Stabilization Agreement (PSA)?":
          "Does the project contain a Project Labor Agreement (PLA) or Project Stabilization Agreement (PSA)?",
      },
      {
        "Has a PLA or PSA document been provided?":
          "Has a PLA or PSA document been provided?",
      },
    ],
    "Disadvantaged Business Enterprise (DBE)": [
      {
        "What are the goals and / or requirements for Disabled Veteran Business Enterprise (DVBE) participation?":
          "Are there any goals and / or requirements for Disabled Veteran Business Enterprise (DVBE) participation?",
      },
      {
        "Does this project have any requirements for minority or women owned businesses?":
          "Does this project have any requirements for minority or women owned businesses?",
      },
      {
        "Does this project have any requirements for locally owned businesses?":
          "Does this project have any requirements for locally owned businesses?",
      },
    ],
  };

  const chatbotRef = useRef();

  useEffect(() => {
    // Call clearChat whenever selectedCategory or selectedKey changes
    if (chatbotRef.current) {
      chatbotRef.current.clearChat();
    }
  }, [selectedCategory, selectedKey]);

  // Assuming `selectedCategory` and `selectedItemIndex` are your state variables
  const selectedItem = CATEGORIES[selectedCategory]
    ? CATEGORIES[selectedCategory][selectedItemIndex]
    : undefined;

  function cleanJsonString(str) {
    // This replaces control characters with their escaped versions
    // You might need to adjust the regex to fit your specific needs
    // return str.replace(/[\u0000-\u001F]+/g, "");

    // This replaces control characters with an empty string
    let cleaned = str.replace(/[\u0000-\u001F]+/g, "");

    // Optionally, you could also replace other characters that might cause issues
    cleaned = cleaned.replace(/[\u2028\u2029]+/g, "");

    return cleaned;
  }

  function preprocessMarkdown(markdownText) {
    let processedText = markdownText.replace(/\^\{(.*?)\}/g, "<sup>$1</sup>");
    processedText = processedText.replace(/_\{(.*?)\}/g, "<sub>$1</sub>");

    return processedText;
  }

  function formatForMarkdown(str) {
    let formatted = str.replace(/(?<!\n\n)(\d+\.\s)/g, "\n\n$1");
    return formatted;
  }

  // useeffect for report content logging
  useEffect(() => {
    console.log("Report content:", reportContent);
  }, [reportContent]);

  // close chat when the report, category, or item changes
  useEffect(() => {
    setOpenedChat({ listId: null, index: null });
  }, [selectedCategory, selectedItemIndex]);

  // Utility function to normalize text for comparison
  function normalizeText(text) {
    return text
      .toLowerCase() // Convert to lowercase
      .replace(/[^\w\s]|_/g, "") // Remove punctuation
      .replace(/\s+/g, " ") // Replace multiple spaces with a single space
      .trim(); // Trim whitespace from both ends
  }

  return (
    <div className="flex h-screen">
      <main className="flex-1 overflow-auto">
        <div>
          <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
            <div className="pt-4">
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <h1 className="text-xl mb-2">
                    Project Risk Reports and Notes
                  </h1>

                  <div className="mt-4 border border-gray-400 p-4 rounded-lg w-full">
                    {/* Select Project */}

                    <p className="text-md font-semibold mb-2">Select an RFP:</p>
                    <select
                      onChange={handleProjectSelectionChange}
                      value={selectedProjectId}
                      className="w-full p-2 border border-gray-400 rounded-lg mb-4"
                    >
                      <option value="">Select a Project</option>
                      {projects.map((project) => (
                        <option
                          key={project.project_id}
                          value={project.project_id}
                        >
                          {project.name}
                        </option>
                      ))}
                    </select>

                    {/*  Select Category text */}
                    <p className="text-md font-semibold mb-2">
                      Select a Topic Category:
                    </p>
                    <Box className="flex mt-2 overflow-x-auto overflow-y-hidden">
                      {Object.keys(CATEGORIES).map((category, index) => (
                        <Button
                          key={category}
                          variant={
                            selectedCategoryIndex === index
                              ? "contained"
                              : "outlined"
                          }
                          style={{
                            display: "inline-flex",
                            alignItems: "center",
                            justifyContent: "center",
                            overflow: "visible", // Allow the content to define the size
                            whiteSpace: "normal", // Allows text wrapping only if needed
                            padding: "10px 20px", // Adjusted for a more squarish appearance
                            margin: "0 12px 12px 0", // Adjusted margin for spacing
                            fontSize: "1rem", // Maintained font size for visibility
                            fontWeight: "bold", // Bold font weight for emphasis
                            backgroundColor:
                              selectedCategoryIndex === index
                                ? "#007BFF" // Changed to a bolder, more noticeable color
                                : "#f0f0f0", // Softened unselected color for contrast
                            color:
                              selectedCategoryIndex === index
                                ? "white"
                                : "#333", // Adjusted for better contrast
                            borderRadius: "8px", // Less rounded for a more squarish appearance
                            boxShadow: "0 2px 4px rgba(0,0,0,0.2)", // Adjusted shadow for depth
                          }}
                          onClick={() => {
                            setSelectedCategoryIndex(index);
                            setSelectedCategory(category);
                            setSelectedItemIndex(0);
                          }}
                        >
                          {category}
                        </Button>
                      ))}
                    </Box>

                    {/* Item Buttons */}
                    <p className="text-md font-semibold mb-2">
                      Select an Item:
                    </p>
                    <Box
                      className="flex flex-wrap mt-2" // Utilize flex-wrap for item wrapping
                      style={{ whiteSpace: "normal" }}
                    >
                      {(
                        CATEGORIES[
                          Object.keys(CATEGORIES)[selectedCategoryIndex]
                        ] || []
                      ).map((item, index) => (
                        <Button
                          key={index}
                          variant={
                            selectedItemIndex === index
                              ? "contained"
                              : "outlined"
                          }
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            whiteSpace: "normal", // Enable text wrapping
                            padding: "6px 16px", // Adjust padding if needed to reduce button size
                            margin: "0 8px 8px 0", // Adjust margins for a more compact layout
                            backgroundColor:
                              selectedItemIndex === index
                                ? "rgba(0, 123, 255, 0.1)"
                                : "white", // Transparent blue fill when selected, white otherwise
                            color:
                              selectedItemIndex === index ? "#007BFF" : "#333", // Blue text when selected, darker otherwise
                            border: "1px solid #007BFF", // Blue outline for all buttons
                            borderRadius: "20px", // Very rounded borders
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            flex: "0 1 auto", // Adjust flex-grow to 0 to prevent button from growing
                            cursor: "pointer", // Change cursor to indicate clickable
                            boxShadow: "0 2px 4px rgba(0,0,0,0.1)", // Soft shadow for depth
                            minWidth: "0", // Ensure button width is only as wide as its content
                          }}
                          onClick={() => setSelectedItemIndex(index)}
                        >
                          <span style={{ whiteSpace: "normal" }}>{item}</span>
                        </Button>
                      ))}
                    </Box>
                  </div>

                  {/* hide if no selectedIndex or category index */}
                  {/* {selectedCategory && selectedItemIndex && ( */}
                  <div className="mt-2">
                    {filteredReports.map((report) => (
                      <Fragment key={report.report_id}>
                        {report.document_type === "Risk Report" && (
                          <div className="mt-4 border border-gray-400 p-4 rounded-lg">
                            <h3 className="text-xl font-semibold mb-4 underline">
                              {/* {report.title} */}
                              Identified Risks
                            </h3>
                            {Object.entries(report.text).map(
                              ([category, details]) => {
                                if (
                                  CATEGORIES[selectedCategory] &&
                                  CATEGORIES[selectedCategory].includes(
                                    category
                                  )
                                ) {
                                  if (category === selectedItem) {
                                    let conflictExplanation =
                                      "No valid conflict explanation available."; // Default message

                                    try {
                                      const cleanedOutput = cleanJsonString(
                                        details.output
                                      );
                                      // Attempt to parse the JSON string
                                      const parsedDetails = JSON.parse(
                                        // details.output
                                        cleanedOutput
                                      );
                                      // Check if there are any risks and if the array is not empty
                                      if (
                                        parsedDetails.risks &&
                                        parsedDetails.risks.length > 0
                                      ) {
                                        return (
                                          <div
                                            key={category}
                                            className="mt-4 mb-4"
                                          >
                                            <ol className="list-decimal pl-4 mb-4 pb-4">
                                              {parsedDetails.risks.map(
                                                (risk, index) => (
                                                  <li
                                                    key={index}
                                                    className="mb-2 pb-2 border-t border-gray-400"
                                                  >
                                                    <div className="flex justify-between items-start pt-2">
                                                      <div className="flex flex-col gap-2">
                                                        <h3 className="text-lg font-semibold mb-2 mt-2">
                                                          Risk Explanation:
                                                        </h3>
                                                        <span>
                                                          {risk[
                                                            "Conflict Explanation"
                                                          ] ||
                                                            "No explanation provided."}
                                                        </span>

                                                        {/* ADDED UNCHECKED */}
                                                        <h3 className="text-md font-semibold mt-2">
                                                          Suggested Alternative
                                                          Language:
                                                        </h3>
                                                        <span className="mb-4">
                                                          {risk[
                                                            "Suggested Alternate Language"
                                                          ] ||
                                                            "No alternative language provided"}
                                                        </span>
                                                      </div>

                                                      <Button
                                                        variant="outlined"
                                                        onClick={() => {
                                                          if (
                                                            openedChat.listId ===
                                                              report.report_id &&
                                                            openedChat.index ===
                                                              index
                                                          ) {
                                                            // If the chat is already open for this item, close it
                                                            setOpenedChat({
                                                              listId: null,
                                                              index: null,
                                                            });
                                                          } else {
                                                            // Otherwise, open the chat for this item
                                                            setOpenedChat({
                                                              listId:
                                                                report.report_id,
                                                              index: index,
                                                            });
                                                            const metadataSources =
                                                              details.metadata
                                                                ? details.metadata.map(
                                                                    (md) =>
                                                                      md.source
                                                                  )
                                                                : [];
                                                            const metadataTexts =
                                                              details.metadata
                                                                ? details.metadata.map(
                                                                    (md) =>
                                                                      md.text
                                                                  )
                                                                : [];

                                                            setReportContent({
                                                              ...risk,
                                                              sources:
                                                                metadataSources,
                                                              texts:
                                                                metadataTexts,
                                                            });
                                                          }
                                                        }}
                                                        style={{
                                                          display:
                                                            "inline-flex",
                                                          alignItems: "center",
                                                          justifyContent:
                                                            "center",
                                                          whiteSpace: "nowrap",
                                                          minWidth:
                                                            "fit-content",
                                                          padding: "6px 12px",
                                                          backgroundColor:
                                                            openedChat.listId ===
                                                              report.report_id &&
                                                            openedChat.index ===
                                                              index
                                                              ? "#4CAF50"
                                                              : "#718096", // Change color if selected
                                                          color: "white",
                                                        }}
                                                      >
                                                        Risk Chat {index + 1}
                                                      </Button>
                                                    </div>
                                                  </li>
                                                )
                                              )}
                                            </ol>
                                          </div>
                                        );
                                      } else {
                                        // Handle the case where there are no risks
                                        return (
                                          <div
                                            key={category}
                                            className="mt-4 mb-4"
                                          >
                                            <h4 className="text-lg font-semibold mb-2">
                                              {category}
                                            </h4>
                                            <p>No risks found.</p>
                                          </div>
                                        );
                                      }
                                    } catch (error) {
                                      console.error(
                                        "Error parsing details.output for category:",
                                        category,
                                        error
                                      );
                                      // Optionally handle the error, e.g., by setting a fallback message
                                      return (
                                        <div
                                          key={category}
                                          className="mt-4 mb-4"
                                        >
                                          <h4 className="text-lg font-semibold mb-2">
                                            {category}
                                          </h4>
                                          <p>
                                            Error displaying risk explanations.
                                          </p>
                                        </div>
                                      );
                                    }
                                  }
                                } else {
                                  return null; // If not matching, don't render anything
                                }
                              }
                            )}
                          </div>
                        )}

                        {report.document_type === "Note" && (
                          <div className="mt-4 border border-gray-400 p-4 rounded-lg">
                            <h3 className="text-xl font-semibold mb-4 underline">
                              {/* {report.title} */}
                              Cliff's Notes
                            </h3>

                            {/* {Object.entries(QUESTIONS).map(
                              ([category, questions]) => {
                                if (category === selectedItem) {
                                  return (
                                    <div key={category} className="mt-4 mb-4">
                                      {questions.map((qObj, index) => {
                                        const questionText =
                                          Object.keys(qObj)[0]; // Get the question text from the QUESTIONS object
                                        const questionDetail =
                                          Object.values(qObj)[0]; // Get the question detail (description)
                                        const matchingDetail = Object.entries(
                                          report.text
                                        ).find(([reportQuestion, _]) =>
                                          reportQuestion.includes(
                                            questionDetail
                                          )
                                        );
                                        let response =
                                          "Response not available.";
                                        if (
                                          matchingDetail &&
                                          matchingDetail[1] &&
                                          matchingDetail[1].output
                                        ) {
                                          try {
                                            const cleanedOutput =
                                              cleanJsonString(
                                                matchingDetail[1].output
                                              );
                                            const parsedOutput =
                                              JSON.parse(cleanedOutput);

                                            if (parsedOutput.response) {
                                              const markdownResponse =
                                                formatForMarkdown(
                                                  parsedOutput.response
                                                );

                                              response = (
                                                <ReactMarkdown
                                                  remarkPlugins={[remarkGfm]}
                                                  children={preprocessMarkdown(
                                                    markdownResponse
                                                  )}
                                                />
                                              ); */}
                            {Object.entries(QUESTIONS).map(
                              ([category, questions]) => {
                                if (category === selectedItem) {
                                  return (
                                    <div key={category} className="mt-4 mb-4">
                                      {questions.map((qObj, index) => {
                                        const questionText =
                                          Object.keys(qObj)[0]; // Get the question text from the QUESTIONS object
                                        const questionDetail =
                                          Object.values(qObj)[0]; // Get the question detail (description)

                                        // Find the matching response in report.text with normalized comparison
                                        const matchingDetail = Object.entries(
                                          report.text
                                        ).find(([reportQuestion, _]) =>
                                          normalizeText(
                                            reportQuestion
                                          ).includes(
                                            normalizeText(questionDetail)
                                          )
                                        );

                                        let response =
                                          "Response not available.";
                                        if (
                                          matchingDetail &&
                                          matchingDetail[1] &&
                                          matchingDetail[1].output
                                        ) {
                                          try {
                                            const cleanedOutput =
                                              cleanJsonString(
                                                matchingDetail[1].output
                                              );
                                            const parsedOutput =
                                              JSON.parse(cleanedOutput);

                                            if (parsedOutput.response) {
                                              // Apply markdown formatting
                                              const markdownResponse =
                                                formatForMarkdown(
                                                  parsedOutput.response
                                                );

                                              response = (
                                                <ReactMarkdown
                                                  remarkPlugins={[remarkGfm]}
                                                  children={preprocessMarkdown(
                                                    markdownResponse
                                                  )}
                                                />
                                              );
                                            } else {
                                              response =
                                                "Response not available.";
                                            }
                                          } catch (error) {
                                            console.error(
                                              "Error parsing response for question:",
                                              questionText,
                                              error
                                            );
                                          }

                                          return (
                                            <div
                                              key={index}
                                              className="mt-4 mb-4 flex justify-between items-start border-t border-gray-400 pt-2"
                                            >
                                              <div>
                                                <p>
                                                  <strong>
                                                    {questionText}
                                                  </strong>
                                                </p>
                                                <div className="ml-4 mb-4 mt-2">
                                                  {response}
                                                </div>
                                              </div>
                                              <Button
                                                variant="outlined"
                                                onClick={() => {
                                                  if (
                                                    openedChat.listId ===
                                                      report.report_id &&
                                                    openedChat.index === index
                                                  ) {
                                                    setOpenedChat({
                                                      listId: null,
                                                      index: null,
                                                    }); // Close the chat if it's already opened
                                                  } else {
                                                    setOpenedChat({
                                                      listId: report.report_id,
                                                      index: index,
                                                    }); // Open the chat if it's not already opened

                                                    if (
                                                      matchingDetail &&
                                                      matchingDetail[1] &&
                                                      matchingDetail[1].metadata
                                                    ) {
                                                      const sources =
                                                        matchingDetail[1].metadata.map(
                                                          (md) => md.source
                                                        );
                                                      const texts =
                                                        matchingDetail[1].metadata.map(
                                                          (md) => md.text
                                                        );

                                                      setReportContent({
                                                        question: questionText,
                                                        detail: questionDetail,
                                                        response:
                                                          response.props
                                                            .children,
                                                        sources, // Pass array of sources
                                                        texts, // Pass array of texts
                                                      });
                                                    }
                                                  }
                                                }}
                                                style={{
                                                  display: "inline-flex",
                                                  alignItems: "center",
                                                  justifyContent: "center",
                                                  whiteSpace: "nowrap",
                                                  minWidth: "fit-content",
                                                  padding: "6px 12px",
                                                  backgroundColor:
                                                    openedChat.listId ===
                                                      report.report_id &&
                                                    openedChat.index === index
                                                      ? "#4CAF50"
                                                      : "#718096", // Change color if selected
                                                  color: "white",
                                                }}
                                              >
                                                Topic Chat
                                              </Button>
                                            </div>
                                          );
                                        }
                                      })}
                                    </div>
                                  );
                                } else {
                                  return null;
                                }
                              }
                            )}
                          </div>
                        )}

                        {/* End */}
                      </Fragment>
                    ))}
                  </div>
                  {/* )} */}
                </FormControl>
              </Grid>
            </div>
          </div>
        </div>
      </main>

      {openedChat.listId && openedChat.index !== null && (
        <aside className="w-1/3 ml-4 overflow-auto flex flex-col h-screen bg-gray-800">
          <div className="h-full overflow-auto">
            <Grid item xs={12} className="h-full">
              <Chatbot
                currentMenuItem={currentMenuItem}
                setCurrentMenuItem={setCurrentMenuItem}
                user={user}
                selectedCategory={selectedCategory}
                selectedKey={selectedKey}
                className="h-full"
                requestType={"report"}
                reportContent={reportContent}
                ref={chatbotRef}
              />
            </Grid>
          </div>
        </aside>
      )}
    </div>
  );
}

export default ReportsView;
