import React from "react";
import DataIntakeView from "../components/DataIntakeView";

function DataIntake({
  currentMenuItem,
  setCurrentMenuItem,
  projectId,
  setProjectId,
  reportId,
  setReportId,
  user,
}) {
  return (
    <div>
      <main className="flex-1">
        <div className="py-6">
          <DataIntakeView
            currentMenuItem={currentMenuItem}
            setCurrentMenuItem={setCurrentMenuItem}
            projectId={projectId}
            setProjectId={setProjectId}
            reportId={reportId}
            setReportId={setReportId}
            user={user}
          />
        </div>
      </main>
    </div>
  );
}

export default DataIntake;
